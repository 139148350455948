import React from "react";
import {MessageDisplay} from "../components/Message";
import {Link} from "react-router-dom";

export default function NotFoundPage() {
    return (
        <div className="container">
            <h1>Seite nicht gefunden</h1>
            <MessageDisplay message={{type: "warning", message: "Entschuldigung, diese Seite scheint nicht zu existieren."}}/>
            <Link to="/" className="text-center d-block">Hier gehts zur Startseite.</Link>
        </div>);
};