import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {authRegister, authRegisterErrors, UserRegisterInfo} from "./AuthenticationService";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorObject, getError, isLoggedIn, setToken} from "../api/apiConfig";
import {Button, Card, Form} from "react-bootstrap";
import {CheckAll, GlobeAmericas, KeyFill} from "react-bootstrap-icons";
import InputControlled from "../components/InputControlled";
import {Link, useNavigate} from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";

export interface RegisterErrorState {
    email?: boolean;
    password?: boolean;
    password2?: boolean;
}

export const registerInputClass = (value?: boolean): string => {
    if(value === undefined) return "";
    return value ? "is-valid" : "is-invalid";
}

export default function RegisterPage() {
    const [submitting, submittingSet] = useState(false);
    const [errors, errorsSet] = useState<RegisterErrorState>({email: undefined, password: undefined, password2: undefined});
    const [message, messageSet] = useState<MessageState>();
    const [registerInfo, registerInfoSet] = useState<UserRegisterInfo>({email: "", password: "", password2: ""});

    const navigate = useNavigate();
    const onRegister = useCallback((event: FormEvent) => {
        event.preventDefault();
        if(!registerInfo) return;

        submittingSet(true);
        messageSet(undefined);
        authRegister(registerInfo).then((response: AxiosResponse<string>) => {
            //messageSet({type: "success", message: "Du wurdest erfolgreich registriert."});
            setToken(response.data);
            navigate("/app");
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, authRegisterErrors)});
        }).finally(() => submittingSet(false));
    }, [navigate, registerInfo]);

    useEffect(() => {
        if(isLoggedIn()) {
            navigate("/app");
        }
    }, [navigate])

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{height: "95vh"}}>
            <Card style={{maxWidth: "400px"}} className="w-100">
                <Form onSubmit={onRegister} autoComplete="off">
                    <Card.Body>
                        <MessageDisplay message={message}/>
                        <Card.Title>Registrierung</Card.Title>
                        <Form.Group className="mb-3">
                            <Form.Label className="text-dark text-left"><GlobeAmericas /> Email Addresse</Form.Label>
                            {/*<div className="fs-6 text-muted">
                                We'll never share your email with anyone else.
                            </div>*/}
                            <InputControlled type="email" name="email" autoComplete="email" value={registerInfo.email} className={"form-control " + registerInputClass(errors.email)} handleChange={(v: string) => {
                                const x = {...registerInfo};
                                x.email = v;
                                registerInfoSet(x);
                                const er = {...errors};
                                er.email = x.email.indexOf("@") !== -1;
                                errorsSet(er);
                            }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><KeyFill /> Passwort</Form.Label>
                            <InputControlled type="password" name="password" value={registerInfo.password} className={"form-control " + registerInputClass(errors.password)} handleChange={(v: string) => {
                                const x = {...registerInfo};
                                x.password = v;
                                registerInfoSet(x);
                                const er = {...errors};
                                er.password = x.password.length >= 6;
                                errorsSet(er);
                            }}/>
                            <PasswordStrengthBar className="px-2" minLength={6} password={registerInfo.password} scoreWords={['Schwach', 'Schwach', 'Gut', 'Stark', 'Sehr Stark']} shortScoreWord="Zu kurz" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><CheckAll /> Passwort Wiederholung</Form.Label>
                            <InputControlled type="password" name="password-repeat" value={registerInfo.password2} className={"form-control " + registerInputClass(errors.password2)} handleChange={(v: string) => {
                                const x = {...registerInfo};
                                x.password2 = v;
                                registerInfoSet(x);
                                const er = {...errors};
                                er.password2 = registerInfo.password === x.password2;
                                errorsSet(er);
                            }}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                    <div className='d-flex py-2 flex-column align-items-center'>
                        <Button variant="primary" type="submit" disabled={submitting
                            || registerInfo.email.indexOf("@") === -1
                            || registerInfo.password !== registerInfo.password2
                            || registerInfo.password.length < 6}>
                            Registrieren
                        </Button>
                        </div>
                        <div className="d-flex py-2 flex-column align-items-center small">
                            <p className='text-muted mb-0'>Du hast schon einen Account?</p>
                            <Link to="/app/login" className='text-link'>Einloggen</Link>
                        </div>
                    </Card.Footer>
                </Form>
            </Card>
        </div>);
};