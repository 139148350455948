import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import {Footer} from "../basic/Footer";


export default function WebpageOutlet() {

    const navLinks = [
        {to: "/", title: "Home", end: true},
        // {to: "/app/friends", title: "Freunde"},
        // {to: "/app/list", title: "Geschenke"},
        // {to: "/app/profile", title: "Profile"}
    ]

    return <>
        <Header links={navLinks} webpage />
        <Outlet />
        <Footer />
    </>
}