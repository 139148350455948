import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import AppOutletBreadcrumb from "./AppOutletBreadcrumb";
import {Footer} from "../basic/Footer";



export default function AppOutlet() {

    const isAuthenticated = useAuth().authenticated
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (!isAuthenticated) {
            const redirectURL = encodeURIComponent(location.pathname + location.search)
            navigate("/app/login?redirect=" + redirectURL)
        }
    })

    const navLinks = [
        {to: "/app", title: "Startseite", end: true},
        {to: "/app/list", title: "Geschenke"},
        {to: "/app/profile", title: "Profil"}
    ]

    return <>
        <Header links={navLinks} />
        <AppOutletBreadcrumb />
        <main className="mt-4 p-2">
            <section className="inner-page">
                <Outlet />
            </section>
        </main>
        <Footer />
        <button className="back-to-top d-flex align-items-center justify-content-center btn btn-link"><i
            className="bi bi-arrow-up-short"></i></button>
    </>
}