import {ReactElement} from "react";
import {getPayload} from "../api/apiConfig";

export default function UserNameDisplay(props: {user: {name?: string, uid?: never, id: number} | {name?: string, uid: number, id?: never},selfString?: string}): ReactElement {
    const payload = getPayload();
    const uid = props.user.uid ? props.user.uid : props.user.id;

    // self
    if(payload && payload.id===uid)
        return <b>{props.selfString ? props.selfString : "Du"}</b>;

    // other user
    return <b>{props.user.name}</b>;
}