import React, {FormEvent, useCallback, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {Button, Card, Form} from "react-bootstrap";
import InputControlled from "../components/InputControlled";
import {CheckAll, KeyFill} from "react-bootstrap-icons";
import PasswordStrengthBar from "react-password-strength-bar";
import {RegisterErrorState, registerInputClass} from "./RegisterPage";
import {Link, useSearchParams} from "react-router-dom";
import {authForgotCode} from "./AuthenticationService";
import {AxiosError, AxiosResponse} from "axios";
import {errorMessageBase, ErrorObject, getError, setToken} from "../api/apiConfig";
import {socialUserPasswordChange} from "../social/SocialService";

interface ChangeData {
    email: string;
    code: string;
    password: string;
    password2: string;
}

export const ForgotSecondPage = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [stage, stageSet] = useState(0);
    const [errors, errorsSet] = useState<RegisterErrorState>({email: undefined, password: undefined, password2: undefined});
    const [data, dataSet] = useState<ChangeData>({email: '', code: '', password: '', password2: ''});

    const handleCode = useCallback(() => {
        const uid: string | null = searchParams.get('uid');
        const link: string | null = searchParams.get('link');
        if(!link || !uid || data.code.length!==5) {
            return;
        }
        if(submitting) return;

        submittingSet(true);
        authForgotCode(+uid, link, data.code).then((response: AxiosResponse<string>) => {
            setToken(response.data); // = login - can use normal change password request
            stageSet(1);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [data.code, searchParams, submitting]);
    const handlePassword = useCallback((event: FormEvent) => {
        event.preventDefault();
        if(submitting) return;
        if(data.password !== data.password2) return;

        submittingSet(true);
        socialUserPasswordChange(data.password).then(() => {
            stageSet(2);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [data.password, data.password2, submitting]);

    return <div className="container d-flex align-items-center justify-content-center" style={{height: "95vh"}}>
        <Card style={{maxWidth: "400px"}} className="w-100">
            {/*Input code*/ stage===0 && <>
                <Card.Body>
                    <Card.Title>Passwort zurücksetzen</Card.Title>
                    <MessageDisplay message={message}/>
                    <h6>Bitte gib den Sicherheitscode ein, den Du per Email bekommen hast.</h6>

                    <Form.Group>
                        <Form.Label>Sicherheitscode</Form.Label>
                        <InputControlled value={data.code} name="code" pattern="[A-Z0-9]{5}" className="form-control" handleChange={(v: string) => data.code = v}/>
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <div className='d-flex py-2 flex-column align-items-center'>
                        <Button variant="primary" onClick={handleCode}>Sicherheitscode bestätigen</Button>
                    </div>
                    <div className="d-flex py-2 flex-column align-items-center small">
                        <p className='text-muted mb-0'>Du weißt Dein Passwort wieder?</p>
                        <Link to="/app/login">Zurück zum Login</Link>
                    </div>
                </Card.Footer>
            </>}
            {/*Set new password*/ stage===1 && <>
                <Form onSubmit={handlePassword}>
                    <Card.Body>
                        <Card.Title>Passwort zurücksetzen</Card.Title>
                        <MessageDisplay message={message}/>
                        <h6 className="text-success">Sicherheitscode hat gestimmt!</h6>
                        <h6>Bitte speichere ein neues Passwort.</h6>

                        <Form.Group>
                            <Form.Label><KeyFill /> Passwort</Form.Label>
                            <InputControlled type="password" name="password" value={data.password} className={"form-control " + registerInputClass(errors.password)} handleChange={(v: string) => {
                                const x = {...data};
                                x.password = v;
                                dataSet(x);
                                const er = {...errors};
                                er.password = x.password.length >= 6;
                                errorsSet(er);
                            }}/>
                            <PasswordStrengthBar className="px-2" minLength={6} password={data.password} scoreWords={['Schwach', 'Schwach', 'Gut', 'Stark', 'Sehr Stark']} shortScoreWord="Zu kurz" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><CheckAll /> Passwort Wiederholung</Form.Label>
                            <InputControlled type="password" name="password2" value={data.password2} className={"form-control " + registerInputClass(errors.password2)} handleChange={(v: string) => {
                                const x = {...data};
                                x.password2 = v;
                                dataSet(x);
                                const er = {...errors};
                                er.password2 = data.password === x.password2;
                                errorsSet(er);
                            }}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex py-2 flex-column align-items-center'>
                            <Button variant="primary" type="submit">Neues Passwort speichern</Button>
                        </div>
                    </Card.Footer>
                </Form>
            </>}

            {/*Success message*/ stage===2 && <>
                <Card.Body>
                    <Card.Title>Passwort zurücksetzen</Card.Title>
                    <MessageDisplay message={message}/>

                    <h5 className="text-success">Passwort erfolgreich geändert.</h5>
                    <Link to="/app">Zur Startseite</Link>
                </Card.Body>
            </>}
        </Card>
    </div>;
};