import {
    Claim,
    ClaimGroup,
    Item,
    ListUserInfo,
    presentsClaim,
    presentsClaimDelete,
    presentsClaimErrors,
    presentsClaimGet,
    presentsItemsChange,
    presentsItemsChangeErrors,
    presentsItemSelfClaim,
    presentsItemSelfClaimErrors,
    presentsItemsNew,
    presentsItemsNewErrors,
    presentsListGetWithoutItems,
    presentsPriorityText,
    PriorityInfo,
    UserDataSettingsList
} from "./PresentsService";
import React, {FormEvent, ReactElement, useCallback, useEffect, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {errorMessageBase, ErrorObject, getError, getPayload} from "../api/apiConfig";
import {AxiosError, AxiosResponse} from "axios";
import {Button, Form, Modal} from "react-bootstrap";
import InputControlled, {
    CheckboxControlled,
    SelectControlled,
    SelectNumberControlled,
    TextareaControlled
} from "../components/InputControlled";
import {dateStringToDate, dateStringToString, dateStringToTimeString, indexGet} from "../api/util";
import {PresentsHighPriorityStar, PresentsSelfClaimedText} from "./PresentsCommon";
import {UserDataNameId} from "../social/SocialService";
import UserNameDisplay from "../components/UserNameDisplay";
import {CalendarHeart} from "react-bootstrap-icons";

type State = "new" | "edit" | "requestNew" | "requestEdit" | "outside";

function PresentUserListChange(list: UserDataNameId[], uInfo: UserDataNameId, v: boolean) {
    if(!list) return;
    const index: number = list.findIndex((u: UserDataNameId) => u.id===uInfo.id);

    //add
    if(v) {
        if(index!==-1) return; // no duplicates
        list.push(uInfo);
        return;
    }

    //remove
    if(index>=0) list.splice(index, 1);
}

const ClaimGroupDisplay = (props: {listUid: number, claimGroup: ClaimGroup,
    handleClaim: (claim: Claim, claimGroup: ClaimGroup, state: State, callback?: () => void) => void,
    handleClaimDelete: (claim: Claim, claimGroup: ClaimGroup, state: State, callback?: () => void) => void,
    friendsList: UserDataNameId[],
    itemOriginal: Item, //note: must be reference to original input item, not copy of ItemModal
    listRerender: () => void }
) => {
    const [state, stateSet] = useState<State>();
    const [claimGroup, claimGroupSet] = useState<ClaimGroup>();
    const [claim, claimSet] = useState<Claim>();
    const [friendsNotShared, friendsNotSharedSet] = useState<UserDataNameId[]>();

    const [share, shareSet] = useState(false);
    const [helpShare, helpShareSet] = useState(false);
    const [helpShareOk, helpShareOkSet] = useState(false);
    const [helpGiveDate, helpGiveDateSet] = useState(false);

    // update claim
    useEffect(() => {
        claimGroupSet(props.claimGroup);
        const payload = getPayload(); // note: if outside -> endless re-render

        // set existing self claim
        if(props.claimGroup.selfClaim) claimSet(props.claimGroup.selfClaim);
        // make new self claim
        else if(payload) {
            const isAdmin: boolean = props.claimGroup.adminClaim.uid===payload.id;
            claimSet({
                uid: payload.id,
                iid: props.claimGroup.adminClaim.iid,
                claimedTime: isAdmin ? '' : props.claimGroup.adminClaim.claimedTime,
                giveTime: '',
                lid: props.listUid,
                adminId: props.claimGroup.adminClaim.uid,
                confirmed: null,
                claimedPeople: []
            });
        }
    }, [props.claimGroup, props.listUid]);

    // update state
    useEffect(() => {
        const payload = getPayload();
        if(!payload || !claimGroup || !claim) return;
        const isAdmin: boolean = claimGroup.adminClaim.uid===payload.id;

        if(isAdmin) {
            if(claim.claimedTime==='') stateSet("new")
            else stateSet("edit")
        } else {
            if(!claimGroup.adminClaim.shareOk) stateSet("outside");
            else if(claim.confirmed===null) stateSet("requestNew");
            else stateSet("requestEdit");
        }
    }, [claim, claimGroup]);

    // friendsNotInRequests
    useEffect(() => {
        if(!claimGroup || !props.friendsList || !claim || !claim.claimedPeople) return;
        const list: UserDataNameId[] = [...props.friendsList];
        const record: Record<number, boolean> = {};
        // remove users that are in claimRequests in time O(n + m + m2)
        claimGroup.claimRequests.forEach((c: Claim) => record[c.uid] = true);
        claim.claimedPeople.forEach((uInfo: UserDataNameId) => record[uInfo.id] = true);
        for(let i=list.length-1; i>=0; --i) {
            if(record[list[i].id]) list.splice(i, 1);
        }
        friendsNotSharedSet(list);
    }, [props.friendsList, claimGroup, claim]);

    const rerender = useCallback(() => {
        props.listRerender();

        if(!claim) return;
        const c: Claim = {...claim};
        claimSet(c); // re-render for state change
    }, [claim, props]);

    // handle submit callback of form
    const handleSubmit = useCallback((event: FormEvent, item: Item, claim: Claim) => {
        if(!claimGroup || !state) return;
        event.preventDefault();
        props.handleClaim(claim, claimGroup, state, () => {
            switch (state) {
                case "new": {
                    ++item.claimsCount;
                    ++item.claimsCountOwn;
                    break;
                }
                case "requestNew": {
                    ++item.claimsCountShareWait;
                    break;
                }
            }
            rerender();
        });
    }, [claimGroup, props, rerender, state]);

    // handle claim delete
    const handleDelete = useCallback((item: Item, claim: Claim) => {
        if(!claimGroup || !state) return;
        props.handleClaimDelete(claim, claimGroup, state,() => {
            switch (state) {
                case "edit": {
                    --item.claimsCount;
                    --item.claimsCountOwn;
                    if(claimGroup?.adminClaim.shareOk) --item.claimsCountShare;
                    if( // backend assigns new claim admin
                        (claim.claimedPeople && claim.claimedPeople.length>0)
                        || claimGroup.claimRequests.length>0
                    ) ++item.claimsCount;
                    rerender();
                    break;
                }
                case "requestEdit": {
                    if(claim.confirmed) --item.claimsCountOwn;
                    else --item.claimsCountShareWait;
                    rerender();
                    break;
                }
            }
        });
    }, [claimGroup, props, rerender, state]);

    const payload = getPayload();
    if(!claimGroup || !claim || !payload || !state) {
        return <></>;
    }

    const isAdmin: boolean = claimGroup.adminClaim.uid===payload.id;
    if(!friendsNotShared && isAdmin) {
        return <></>;
    }
    const haveSharedPeople: boolean = (claim.claimedPeople && claim.claimedPeople.length>0) || claimGroup.claimRequests.length>0 || !!claim.shareOk;

    const claimSharedList: UserDataNameId[] = [];
    const claimedWithoutRequestsList: UserDataNameId[] = [];
    if(claim.claimedPeople) claim.claimedPeople.forEach((u: UserDataNameId) => {
        claimSharedList.push(u);
        if(claimGroup.claimRequests.findIndex((c: Claim) => c.uid===u.id)===-1) claimedWithoutRequestsList.push(u);
    });
    if(claimGroup.claimRequests.length>0) claimGroup.claimRequests.forEach((c: Claim) => {
        if(claimSharedList.findIndex((u: UserDataNameId) => u.id===c.uid)!==-1) return;
        if (c.confirmed === null || c.confirmed) claimSharedList.push({id: c.uid, name: c.name ? c.name : ''})
    });
    claimSharedList.sort((a: UserDataNameId,b: UserDataNameId) => a.name.localeCompare(b.name));

    return <Form onSubmit={(event: FormEvent) => handleSubmit(event, props.itemOriginal, claim)} className="well mb-3">
        {state==="new" ? <h5>Neue Reservierung</h5> : <h5>Reservierung von <UserNameDisplay user={claimGroup.adminClaim} selfString="Dir"/></h5>}
        {/*with who currently shared*/ claimSharedList.length>0 && <h6>Reservierung ist geteilt mit {claimSharedList.map((u: UserDataNameId, index: number) => <span key={u.id}>{index>0 && ", "}<UserNameDisplay user={u} selfString="Dir"/></span>)}</h6>}

        {/*giveTime input*/ (state==="new" || state==="edit") ? <Form.Group className="mb-2">
            <Form.Label className="mb-0">
                <CalendarHeart className="fs-5 align-baseline"/> Übergabe Datum <Button type="button" className="py-0 align-baseline" variant="link" onClick={() => helpGiveDateSet(!helpGiveDate)}>Was ist das?</Button>
            </Form.Label>
            {helpGiveDate && <div className="ps-3 text-muted small">Nach diesem Datum wird das Geschenk mit Reservierung in die Historie
                gehen und es wird sichtbar wer das Geschenk reserviert hatte.</div>}
            <InputControlled type="date" value={claim.giveTime} className="form-control" handleChange={(v: string) => {
                if(!claim) return;
                claim.giveTime = v;
            }}/>
        </Form.Group> : <div className="mb-2">
            <div>Übergabe Datum: {claimGroup.adminClaim.giveTime}</div>
        </div>}

        {/*claim request description*/ (state==="requestNew" || state==="requestEdit") && !!claimGroup.adminClaim.shareOk && <div>
            <div className="mb-2">Du kannst dich bei dieser Reservierung beteiligen. Bitte benachrichtige <UserNameDisplay user={claimGroup.adminClaim} selfString="Dir"/>, nachdem du die Anfrage (mittels Button unten) geschickt hast, z.B. per Email an <b>{claimGroup.adminClaim.email}</b> oder über andere Wege, dass du dich beteiligen willst.</div>
            {/*request state: confirmed or not*/ state==="requestEdit" && ((claim.confirmed!==null && claim.confirmed) ? <div className="d-inline-block text-success">
                Anfrage bestätigt.
            </div> : <div className="d-inline-block border-bottom border-danger">
                Warte auf Bestätigigung.
            </div>)}
        </div>}

        {(state==="new" || state==="edit") && <>
            {/*toggle share visibile*/ !haveSharedPeople && <Form.Group className="mb-2">
                <div className="form-check form-switch">
                    <CheckboxControlled value={!!claim.shareOk}
                                        handleChange={(v: boolean) => shareSet(v)}
                                        className="form-check-input"/>
                    <label className="form-check-label">Mit anderen Personen schenken? <Button type="button" className="py-0 align-baseline" variant="link" onClick={() => helpShareSet(!helpShare)}>Was ist das?</Button></label>
                </div>
                {helpShare && <div className="ps-3 text-muted small">Du bist verantwortlich für das Geschenk.
                    Alle Personen die sich beteiligen wollen, musst du selbst bestätigen.
                    Du kannst Personen hinzufügen, (am Besten) nachdem sie dich beim Kauf unterstützt haben.</div>}
            </Form.Group>}

            {/*share stuff*/ (share || haveSharedPeople) && <>
                {/*shareOk*/ <Form.Group className="mb-2">
                    <Form.Label className="mb-0">
                        Beteiligungs Anfragen <Button type="button" className="py-0 align-baseline" variant="link" onClick={() => helpShareOkSet(!helpShareOk)}>Was ist das?</Button>
                    </Form.Label>
                    {helpShareOk && <div className="ps-3 text-muted small">Die Freunde der
                        wünschenden Person können Deine Email bei dieser Reservierung sehen, damit sie dich kontaktieren können, wenn sie sich
                        beteiligen wollen. Wenn du deine Erlaubnis zurücknimmst, nachdem sich schon Personen beteiligt haben, werden keine neuen
                        Personen Anfragen stellen können aber bestehende Anfragen bleiben erhalten.</div>}
                    <div className="form-check form-switch">
                        <CheckboxControlled value={!!claim.shareOk}
                                            handleChange={(v: boolean) => {
                                                const c = {...claim};
                                                c.shareOk = v;
                                                claimSet(c);
                                            }}
                                            className="form-check-input"/>
                        <label className="form-check-label">Ich freue mich, wenn sich andere am Geschenk beteiligen.</label>
                    </div>
                </Form.Group>}

                {/*shared people*/ claim.claimedPeople && <Form.Group className="mb-2">
                    <div>Gemeinsam schenken mit:</div>
                    <div className="ps-3 text-muted small">Du kannst hier Freunde selber hinzufügen, die sich beim Geschenk beteiligen.</div>
                    <div>
                        {/*claim add friends*/ friendsNotShared && <select value="" className="form-select mb-2" onChange={(event) => {
                            const v: string = event.target.value;
                            if(!claim.claimedPeople) return;
                            const id: number = +v;
                            const index = friendsNotShared.findIndex((uI: UserDataNameId) => uI.id===id)
                            if(index===-1) return;
                            PresentUserListChange(claim.claimedPeople, friendsNotShared[index], true)
                            const c = {...claim};
                            claimSet(c);
                        }}>
                            <option value=""></option>
                            {/*friend options*/ friendsNotShared.map((uInfo: UserDataNameId) => <option key={uInfo.id} value={uInfo.id}>{uInfo.name}</option>)}
                        </select>}

                        {claimedWithoutRequestsList.length>0 && <>
                            <h6 className="mb-0">Geteilt mit:</h6>
                            {/*claims added self*/ claimedWithoutRequestsList.map((uInfo: UserDataNameId) => <div key={uInfo.id} className="pb-1">
                                <Button onClick={() => {
                                    if(!claim.claimedPeople) return;
                                    PresentUserListChange(claim.claimedPeople, uInfo, false)
                                    const c = {...claim};
                                    claimSet(c);
                                }}
                                        className="btn btn-danger btn-sm">&times;</Button><span className="ps-2">{uInfo.name}</span>
                            </div>)}
                        </>}

                        {/*claim requests*/ claimGroup.claimRequests.length>0 && <>
                            <h6 className="mb-0">Wollen sich beteiligen:</h6>
                            {claimGroup.claimRequests.map((cl: Claim) => <div key={cl.uid} className="form-check form-switch">
                                <CheckboxControlled value={!!claim.claimedPeople && claim.claimedPeople.some((u: UserDataNameId) => u.id===cl.uid)}
                                                    handleChange={(v: boolean) => {
                                                        if(!claim.claimedPeople) return;
                                                        PresentUserListChange(claim.claimedPeople, {id: cl.uid, name: cl.name?cl.name:''}, v)
                                                        const c = {...claim};
                                                        claimSet(c);
                                                    }}
                                                    className="form-check-input"/>
                                <label className="form-check-label">{cl.name}</label>
                            </div>)}
                        </>}
                    </div>
                </Form.Group>}
            </>}
        </>}

        <div className="mt-2">
            {/*New button*/ state==="new" && <Button variant="primary" type="submit">Reservieren</Button>}
            {/*Claim Request button*/ state==="requestNew" && <Button variant="primary" type="submit">Anfragen für Beteiligung</Button>}
            {/*Change button*/ state==="edit" && <Button variant="primary" type="submit">Änderungen speichern</Button>}
            {/*Delete button*/ (state==="edit" || state==="requestEdit") && <div style={{height: "38px"}}><Button variant="danger" type="button" className="float-end" onClick={() => handleDelete(props.itemOriginal, claim)}>Reservierung löschen</Button></div>}
        </div>
    </Form>
};

export const ItemModal = (props: {
    userInfo: ListUserInfo,
    listUid?: number,
    item?: Item,
    itemSet: (item?: Item) => void,
    itemNewAdd: (itemChanged: Item, id: number) => void,
    listRerender: () => void,
}
): ReactElement => {
    const [item, itemSet] = useState<Item>();
    const [claimList, claimListSet] = useState<ClaimGroup[]>();
    const [claimNew, claimNewSet] = useState<ClaimGroup>();
    const [editing, editingSet] = useState(false);
    const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [friendsList, friendsListSet] = useState<UserDataNameId[]>();

    //load friends
    useEffect(() => {
        presentsListGetWithoutItems().then((response: AxiosResponse<UserDataSettingsList[]>) => {
            friendsListSet(response.data);
        });
    }, []);

    //show/hide when props.item is set
    useEffect(() => {
        // open new item
        if(props.item) {
            // set item
            itemSet({...props.item});
            editingSet(props.item.id === -1);
            const payload = getPayload();
            const selfItem: boolean = props.item.sharedPeople.findIndex((u: UserDataNameId) => u.id===payload.id) !== -1;

            // load claims for item
            if(props.item.id!==-1 && !selfItem) presentsClaimGet(props.item.id).then((response: AxiosResponse<Claim[]>) => {
                if(!payload) return;

                const claims: Claim[] = response.data;
                const claimAdmins: ClaimGroup[] = [];
                const claimAdminsMap: Record<string, ClaimGroup> = {}; // key= "uid claimedTime"

                // admins first, then names (-> next forEach has admins assign first)
                claims.sort((a: Claim, b: Claim) => {
                    const adminA = a.adminId===a.uid;
                    const adminB = b.adminId===b.uid;
                    if(adminA!==adminB) return adminA ? -1 : 1;
                    if(!a.name || !b.name) return 0;
                    return a.name?.localeCompare(b.name);
                })

                // assign to admin groups
                claims.forEach((claim: Claim) => {
                    // remove self from claimedPeople
                    if(claim.claimedPeople) {
                        const index = claim.claimedPeople.findIndex((uI: UserDataNameId) => uI.id===payload.id);
                        if(index!==-1) claim.claimedPeople.splice(index, 1);
                    }

                    if(claim.uid===claim.adminId) {
                        // admin
                        const group: ClaimGroup = {adminClaim: claim, claimRequests: []};
                        claimAdmins.push(group);
                        claimAdminsMap[claim.adminId + " " + claim.claimedTime] = group;
                        if(!claim.claimedPeople) claim.claimedPeople = [];
                    } else {
                        // not admin
                        const group = claimAdminsMap[claim.adminId + " " + claim.claimedTime];

                        // if claim request save in claimRequests, else only in claim.claimedPeople
                        if(claim.confirmed!==null) {
                            group.claimRequests.push(claim);
                        }

                        if(group.adminClaim.claimedPeople && claim.name && (claim.confirmed===null || claim.confirmed)) group.adminClaim.claimedPeople.push({id: claim.uid, name: claim.name})
                    }

                    // own claim
                    if(claim.uid===payload.id) {
                        const group = claimAdminsMap[claim.adminId + " " + claim.claimedTime];
                        group.selfClaim = claim;
                    }
                });

                claimListSet(claimAdmins);

                // set new claim
                if(payload && props.listUid && props.item) {
                    const claim: Claim = {lid: props.listUid,
                        uid: payload.id,
                        adminId: payload.id,
                        iid: props.item.id,
                        claimedTime: '',
                        giveTime: new Date().toISOString().split('T')[0],
                        claimedPeople: [],
                        confirmed: true,
                        shareOk: false
                    };
                    claimNewSet({
                        adminClaim: claim, claimRequests: [], selfClaim: claim
                    });
                }
            }, (error: AxiosError<ErrorObject>) => {
                messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
            })
        } else {
            itemSet(undefined);
        }
    }, [props.item, props.listUid]);

    const handleClose = useCallback(() => props.itemSet(undefined), [props]);
    const handleEditStop = useCallback(() => {
        if(props.item) itemSet({...props.item});
        editingSet(false);
    }, [props.item]);

    const handleItemSubmit = useCallback((event: FormEvent) => {
        event.preventDefault();
        if(!item) return;

        submittingSet(true);
        if(item.id === -1) {
            presentsItemsNew(item).then((response: AxiosResponse<number>) => {
                if(item.creatorId===props.listUid) props.itemNewAdd(item, response.data);
                handleClose();
            }, (error: AxiosError<ErrorObject>) => {
                messageSet({type: "danger", message: getError(error.response?.data, presentsItemsNewErrors)});
            }).finally(() => submittingSet(false));
        } else {
            presentsItemsChange(item).then(() => {
                props.itemNewAdd(item,-1);
                handleClose();
            }, (error: AxiosError<ErrorObject>) => {
                messageSet({type: "danger", message: getError(error.response?.data, presentsItemsChangeErrors)});
            }).finally(() => submittingSet(false));
        }
    }, [handleClose, item, props]);

    const handleClaim = useCallback((claim: Claim, claimGroup: ClaimGroup, state: State, callback?: () => void) => {
        if(!claim) return;

        submittingSet(true);
        presentsClaim(claim).then((response: AxiosResponse<string>) => {
            claim.claimedTime = response.data;
            if(callback) callback();

            // reload
            const itemNow = props.item;
            props.itemSet(undefined);
            claimListSet(undefined);
            setTimeout(() => {
                props.itemSet(itemNow);
            }, 100);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, presentsClaimErrors)});
        }).finally(() => submittingSet(false));
    },[props]);
    const handleClaimDelete = useCallback((claim: Claim, claimGroup: ClaimGroup, state: State, callback?: () => void) => {
        submittingSet(true);
        presentsClaimDelete(claim).then(() => {
            if(callback) callback();

            // reload
            const itemNow = props.item;
            props.itemSet(undefined);
            setTimeout(() => {
                props.itemSet(itemNow);
            }, 100);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, presentsClaimErrors)});
        }).finally(() => submittingSet(false));
    }, [props]);

    // user who wants present claims for themselves
    const handleSelfClaim = useCallback(() => {
        if(!item || submitting || item.claimedSelfDate) return;

        submittingSet(true);
        presentsItemSelfClaim(item).then((response: AxiosResponse<string>) => {
            if(props.item) props.item.claimedSelfDate = response.data;
            handleClose();
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, presentsItemSelfClaimErrors)});
        }).finally(() => submittingSet(false));
    }, [handleClose, item, props.item, submitting]);

    // copy item
    const handleCopyItem = useCallback(() => {
        if(!item) return;
        const payload = getPayload();

        const i: Item = {...item};
        i.id = -1;
        i.creatorId = payload.id;
        i.claimedSelfDate = undefined;
        i.validTime = undefined;
        i.sharedPeople = [];
        props.itemSet(i);
    }, [item, props]);

    const payload = getPayload();
    if(!payload || !props.listUid) return <></>;

    //const canEdit = props.item && props.list && presentsCanEdit(props.list);
    const selfItem = item && item.sharedPeople.findIndex((u: UserDataNameId) => u.id===payload.id) !== -1;
    const canEdit = !selfItem && ((props.item && payload.id===props.item.creatorId) || (item && payload.id===item.creatorId));
    const isValid = item && item.validTime && new Date()>=dateStringToDate(item.validTime);

    return <Modal show={item!==undefined} onHide={handleClose}>
        {item && <>
            <Modal.Header closeButton>
                <Modal.Title><h3 className="mb-0 mt-1">{item.name}</h3></Modal.Title>
                <div>
                    Item Date: {item.validTime}
                </div>
            </Modal.Header>
            <Modal.Body>
                {/*Edit View*/ editing && <Form onSubmit={handleItemSubmit}>
                    {item.id===-1 && <h4>Neues Geschenk</h4>}
                    {item.id!==-1 && <Button variant="link" className="py-0 text-end float-end" onClick={() => editingSet(false)}>Bearbeiten abbrechen</Button>}
                    <Form.Group className="mb-2">
                        <label className="text-dark text-left">Geschenk Name</label>
                        <InputControlled value={item.name} className="form-control" handleChange={(v: string) => item.name = v}/>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <label>Priorität</label>
                        <SelectNumberControlled value={item.priority} className={"form-select " + (item.priority > 0 ? "text-success" : "text-danger")} handleChange={(v: number) => {
                            if(item) {
                                const x = {...item};
                                x.priority = v;
                                itemSet(x)
                            }
                        }}>
                            {presentsPriorityText.map((info: PriorityInfo) =>
                                <option key={info.value} value={info.value} className={info.value > 0 ? "text-success" : "text-danger"}>{info.text}</option>)}
                        </SelectNumberControlled>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <label className="text-dark text-left">Beschreibung</label>
                        <TextareaControlled value={item.description} className="form-control" handleChange={(v: string) => item.description = v}/>
                    </Form.Group>
                    {/*present type and share*/ item.priority > 0 && <>
                        <Form.Group className="mb-2">
                            <label>Geschenk Typ</label>
                            <SelectControlled value={item.type ? "specific" : "general"} className="form-select" handleChange={(v: string) => {
                                const x = {...item};
                                x.type = v === "specific";
                                itemSet(x);
                            }}>
                                <option value="specific">Spezifisch</option>
                                <option value="general">Unspezifisch (generell)</option>
                            </SelectControlled>
                        </Form.Group>
                        {!item.type && <>
                            <div>Dieses Geschenk kann beliebig oft reserviert werden, da es nichts spezifisches ist, sondern eine generelle Idee.</div>
                            <div className="fst-italic">Beispiel: Ich wünsche mir ein Buch, Genre soll Fantasy sein. Freunde können mir daher unterschiedliche Fantasy Bücher schenken  wie z.B. 'Harry Potter' oder 'Eragon'.</div>
                        </>}

                        {/*share*/ item.sharedPeople && props.userInfo.sharePeople && props.userInfo.sharePeople.length>0 && <Form.Group className="mb-2">
                            <label>Gemeinsam wünschen mit:</label>
                            <div>
                                {props.userInfo.sharePeople.map((uInfo: UserDataNameId) => <div key={uInfo.id} className="form-check form-switch">
                                    <CheckboxControlled value={item.sharedPeople.some((u: UserDataNameId) => u.id===uInfo.id)}
                                                        handleChange={(v: boolean) => PresentUserListChange(item?.sharedPeople, uInfo, v)}
                                                        className="form-check-input"/>
                                    <label className="form-check-label">{uInfo.name}</label>
                                </div>)}
                            </div>
                        </Form.Group>}
                    </>}
                </Form>}
                {/*Normal View*/ !editing && <>
                    {canEdit && !isValid && <Button variant="link" className="py-0 text-end float-end" onClick={() => editingSet(true)}>Bearbeiten {item.validTime && <span>(Bis {dateStringToTimeString(item.validTime)} änderbar)</span>}</Button>}

                    <div className="mb-2">
                        {/*Priority*/ item.priority > 0 ? <>
                            <div>Priorität: {indexGet(presentsPriorityText, (x: PriorityInfo) => x.value === item.priority)?.text} {item.priority===2 && <PresentsHighPriorityStar/>}</div>
                            {!item.type && <div>Generelle Geschenkidee</div>}
                        </> : <div className="text-danger">Bitte das nicht schenken.</div>}
                    </div>

                    {/*Shared present*/ item.sharedPeople.length>1 && <div>
                        <div>Dieses Geschenk ist ein gemeinsames Geschenk für:</div>
                        <div className="ps-3">
                            {item.sharedPeople.map((uInfo: UserDataNameId, index: number) => <span key={uInfo.id}>{index>0 && ", "}<b>{uInfo.name}</b></span>)}
                        </div>
                    </div>}

                    {/*Description*/ item.description && <div className="mt-3">
                        <h6 className="mb-0">Beschreibung:</h6>
                        <div className="ps-3">{item.description}</div>
                    </div>}

                    {/*Copy item*/ <div>
                        <Button type="button" variant="secondary" onClick={handleCopyItem}>Ich wünsche mir das auch</Button>
                    </div>}

                    {/*Claim*/ item.priority>0 && <div className="mt-4">
                        {/*not valid: only self can see*/ !isValid ? <div>
                                <div>Das Geschenk ist noch nicht für andere sichtbar. Ab {item.validTime && dateStringToTimeString(item.validTime)} wird es erst für andere sichtbar. Ab dann wirst du dieses Geschenk nicht mehr bearbeiten können. Bis zu diesem Zeitpunkt kannst du das Geschenk löschen.</div>
                                <Button variant="danger" onClick={handleSelfClaim}>Geschenk löschen</Button>
                            </div>
                            : <>{/*cannot reserve: not wanted OR self list*/ !(payload?.id && payload.id===props.listUid) ? <>
                                    {/*Claim Info: Self Claimed*/ props.item?.claimedSelfDate && <div><b className="text-danger">Achtung!</b> Die Person, der diese Liste gehört, will dieses Geschenk selbst kaufen. Falls du dieses Geschenk schon gekauft hast, hast du ab dem <PresentsSelfClaimedText>{dateStringToString(props.item.claimedSelfDate)}</PresentsSelfClaimedText> Zeit, es der Person zu übergeben.</div>}

                                    {/*Claim New*/ props.item && claimNew && friendsList && (!item.type || item.claimsCountOwn===0) && ((!item.type || item.claimsCount===0) ? <div>
                                        <ClaimGroupDisplay listUid={props.listUid} claimGroup={claimNew} itemOriginal={props.item} listRerender={props.listRerender}
                                                           handleClaim={handleClaim} handleClaimDelete={handleClaimDelete} friendsList={friendsList}/>
                                    </div> : <div className="mb-2">Schon von anderen Personen reserviert. {claimList && claimList.some((c: ClaimGroup) => c.adminClaim.shareOk) && <span>Du kannst dich bei den bereits vorhandenen Reservierungen beteiligen.</span>}</div>)}

                                    {/*Existing Claims*/ claimList && friendsList && claimList.map((c: ClaimGroup) => <div key={c.adminClaim.uid + " " + c.adminClaim.claimedTime}>
                                        {props.item && <ClaimGroupDisplay itemOriginal={props.item}
                                                                          listUid={props.listUid!==undefined ? props.listUid : -1} claimGroup={c} listRerender={props.listRerender}
                                                                          handleClaim={handleClaim} handleClaimDelete={handleClaimDelete} friendsList={friendsList}/>}
                                    </div>)}
                                </>
                                : /*self reserve: wait a week*/ <>
                                    <div>Du kannst deine Geschenke auch selber kaufen. Damit sichergestellt wird, dass <b>niemand bereits das Geschenk gekauft hat</b>, kaufe das Geschenk bitte <b>erst nach 1 Woche</b> nachdem du das Geschenk hier markiert hast. Bitte mach das nicht vor einer anstehenden Party (z.B. Geburtstag) sondern erst danach.</div>
                                    {item.claimedSelfDate ? <div><PresentsSelfClaimedText>Bereits angekündigt am {dateStringToString(item.claimedSelfDate)}.</PresentsSelfClaimedText></div>
                                        : <Button variant="warning" onClick={handleSelfClaim}>Geschenk in frühestens 1 Woche selber kaufen</Button>}
                                </>}</>}
                    </div>}
                </>}
            </Modal.Body>
        </>}
        <Modal.Footer>
            <MessageDisplay message={message}/>
            {(!editing || item?.id===-1) && <Button disabled={submitting} variant="secondary" onClick={handleClose}>Schließen</Button>}
            {editing && item?.id!==-1 && <Button disabled={submitting} variant="secondary" onClick={handleEditStop}>Abbrechen</Button>}
            {editing && <Button disabled={submitting} variant="success"
                                onClick={handleItemSubmit}>{props.item?.id === -1 ? 'Speichern' : 'Änderungen Speichern'}</Button>}
        </Modal.Footer>
    </Modal>;
};
