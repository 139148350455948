import {UserDataSettingsList} from "../presents/PresentsService";
import React, {ReactElement, useCallback} from "react";
import {Button, Modal} from "react-bootstrap";

export const FriendListModal = (props: {user: UserDataSettingsList | undefined, userSet: (u?: UserDataSettingsList) => void, handleShareRemove: (u: UserDataSettingsList) => void}): ReactElement => {
    const handleClose = useCallback(() => {
        props.userSet(undefined);
    }, [props]);

    return <Modal show={props.user!==undefined} onHide={handleClose}>
        {props.user && <>
            <Modal.Header closeButton>
                <Modal.Title><h3 className="mb-0 mt-1">Teilen Entfernen</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Möchtest Du die Teilberechtigung von <b>{props.user.name}</b> wirklich entfernen?</h4>
                <div>
                    Falls Du bereits Geschenke mit <b>{props.user.name}</b> teilst, werdet ihr diese Geschenke nicht mehr teilen - es wird für euch beide eine Kopie des Geschenks angelegt. Falls jemand bereits das Geschenk reserviert hat, wird diese Reservierung der Person zugeteilt, in dessen Liste sie das Geschenk reserviert hatten.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Abbrechen</Button>
                <Button variant="danger" onClick={() => {
                    if(props.user) {
                        props.handleShareRemove(props.user);
                        handleClose();
                    }
                }}>Wirklich entfernen</Button>
            </Modal.Footer>
        </>}
    </Modal>;
};
