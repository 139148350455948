import {API_BASE, API_CONFIG, errorMessageBase} from "../api/apiConfig";
import axios, {AxiosResponse} from "axios";
import {UserDataNameId, UserDataNameIdAnimal} from "../social/SocialService";

const API = API_BASE + "/presents"

export interface ListItem {
    name: string;
    bought: boolean;
}

export function listOverviewSort(a: ListOverview, b: ListOverview) {
    //favourite
    if(a.user.isFavourite!==b.user.isFavourite) {
        if(a.user.isFavourite) return -1;
        return 1;
    }

    //do not have present
    if((a.itemsReserved.length>0) !== (b.itemsReserved.length>0)) {
        if(a.itemsReserved.length===0) return -1;
        return 1;
    }

    //alphabet
    return a.user.name.localeCompare(b.user.name);
}

export interface UserDataList extends UserDataNameIdAnimal {
    isFavourite: boolean;
}

export interface UserDataSettingsList extends UserDataNameIdAnimal {
    isFavourite: boolean;
    isShare: boolean;
}

export interface ListOverview {
    user: UserDataList;
    itemsReserved: ListItem[];
}

export interface ListUserInfo {
    name: string;
    animal: string;
    sharePeople?: UserDataNameId[];
}

export interface List extends ListUserInfo {
    items: Item[];
}

export interface ItemWantDont extends ListUserInfo {
    want: Item[];
    general: Item[];
    dont: Item[];
}

export interface Item {
    id: number;
    creatorId: number;
    priority: number;
    name: string;
    type: boolean;
    description: string;
    claimedSelfDate?: string;
    validTime?: string;
    category?: string;
    givenTime?: string;

    sharedPeople: UserDataNameId[];

    //own stuff
    claimsCount: number;
    claimsCountOwn: number;
    claimsCountShare: number;
    claimsCountShareWait: number;
}

export const itemSort = (a: Item, b: Item): number => {
    //>0: sort a after b

    //self claimed items last
    if(!!a.claimedSelfDate && !b.claimedSelfDate) return 1;
    if(!a.claimedSelfDate && !!b.claimedSelfDate) return -1;

    //if claimed self - bottom
    if(a.claimsCountOwn!==0 && b.claimsCountOwn===0) return 1;
    if(a.claimsCountOwn===0 && b.claimsCountOwn!==0) return -1;

    //high priority first
    if(a.priority !== b.priority) return b.priority - a.priority;

    //last name compare
    return a.name.localeCompare(b.name);
};

export interface Claim {
    uid: number;
    iid: number;
    claimedTime: string;

    name?: string;
    email?: string;

    giveTime: string;
    lid: number;
    adminId: number;

    shareOk?: boolean;
    confirmed: boolean | null;
    claimedPeople?: UserDataNameId[];

    title?: string;
    note?: string;
    bought?: string;
    listGroup?: number;
}

export interface ClaimGroup {
    adminClaim: Claim;
    claimRequests: Claim[];
    selfClaim?: Claim;
}

export interface PriorityInfo {
    text: string;
    value: number;
}

export const presentsPriorityText: PriorityInfo[] = [
    {value: 2, text: "Hoch"},
    {value: 1, text: "Normal"},
    {value: 0, text: "Nicht erwünscht"},
];

export const presentsListGetErrors: string[] = errorMessageBase.concat([
    "",
]);

export function presentsListGet(): Promise<AxiosResponse<ListOverview[]>> {
    return axios.get(API+"/list.php", API_CONFIG());
}
export function presentsListGetWithoutItems(): Promise<AxiosResponse<UserDataSettingsList[]>> {
    return axios.get(API+"/list.php?action=profile", API_CONFIG());
}

export const presentsItemsGetErrors: string[] = errorMessageBase.concat([
    "",
]);

export function presentsItemsGet(uid: number): Promise<AxiosResponse<List>> {
    return axios.get(API+"/item.php?uid=" + uid, API_CONFIG());
}

export const presentsItemsNewErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, es scheint so, als hätten Sie nicht die Berechtigung, diese Liste zu verändern.",
]);

export function presentsItemsNew(item: Item): Promise<AxiosResponse<number>> {
    return axios.post(API+"/item.php", item, API_CONFIG());
}

export const presentsItemsChangeErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, es scheint so, als hätten Sie nicht die Berechtigung, diese Liste zu verändern.",
    "Entschuldigung, nur die Person, die das Geschenk erstellt hat, kann es verändern.",
]);

export function presentsItemsChange(item: Item): Promise<AxiosResponse<number>> {
    return axios.put(API+"/item.php", item, API_CONFIG());
}

export const presentsItemSelfClaimErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, es scheint so, als hätten Sie nicht die Berechtigung, dieses Geschenk zu reservieren.",
    "Entschuldigung, das Geschenk wurde schon als selbst gekauft markiert.",
]);
export function presentsItemSelfClaim(item: Item): Promise<AxiosResponse<string>> {
    return axios.delete(API+"/item.php?iid=" + encodeURI("" + item.id), API_CONFIG());
}

export const presentsClaimErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, es scheint so, als hätten Sie nicht die Berechtigung, dieses Geschenk zu reservieren.",
    "Entschuldigung, es ist nicht möglich ein nicht erwünschtes Geschenk zu reservieren.",
    "Entschuldigung, das Geschenk wurde von der Wünschenden Person reserviert.",
    "Entschuldigung, das Geschenk wurde in der Zwischenzeit schon reserviert.", //7
    "Entschuldigung, die Reservierung kann nicht verändert werden, da keine alte Reservierung gefunden wurde. Bitte lade die Seite neu.", //8

    "Entschuldigung, die Beteiligungs Anfrage war nicht erfolgreich, da keine alte Reservierung gefunden wurde. Bitte lade die Seite neu.", //9
    "Entschuldigung, die Beteiligungs Anfrage war nicht erfolgreich, da keine alte Reservierung gefunden wurde. Bitte lade die Seite neu.", //10
    "Entschuldigung, die Beteiligungs Anfrage war nicht erfolgreich, da die reservierende Person in der Zwischenzeit keine Anfragen mehr erlaubt. Bitte lade die Seite neu.", //11
]);

export function presentsClaimGet(iid: number): Promise<AxiosResponse<Claim[]>> {
    return axios.get(API+"/claim.php?iid=" + iid, API_CONFIG());
}

export function presentsClaim(claim: Claim): Promise<AxiosResponse<string>> {
    return axios.post(API+"/claim.php", claim, API_CONFIG());
}

export function presentsClaimDelete(claim: Claim): Promise<AxiosResponse<number>> {
    return axios.delete(API+"/claim.php?iid=" + encodeURI("" + claim.iid) + "&claimedTime=" + encodeURI(claim.claimedTime), API_CONFIG());
}
