import {useCallback, useState} from "react";
import {FriendRequest, socialFriendAccept, socialFriendAcceptErrors, socialFriendRequestsGet} from "./SocialService";
import {errorMessageBase, ErrorObject, getError} from "../api/apiConfig";
import {MessageDisplay, MessageState} from "../components/Message";
import {AxiosError, AxiosResponse} from "axios";
import {ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import {FriendRequestDisplay} from "./FriendRequestDisplay";

export const FriendRequestContainer = (props: {friendRequestCount: number, friendRequestCountSet: (n: number) => void}): JSX.Element => {
    const [message, messageSet] = useState<MessageState>();
    const [open, openSet] = useState(false);
    const [submitting, submittingSet] = useState(false);
    const [friendRequests, friendRequestsSet] = useState<FriendRequest[] | null>(null);

    const handleOpen = useCallback(() => {
        openSet(!open);

        // load friend requests for the first time
        if(!open && !friendRequests) {
            socialFriendRequestsGet().then((response: AxiosResponse<FriendRequest[]>) => {
                friendRequestsSet(response.data)
            }, (error: AxiosError<ErrorObject>) => {
                messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
            })
        }
    }, [friendRequests, open]);

    const handleRequest = useCallback((request: FriendRequest, accept: boolean) => {
        if(friendRequests===null) return;
        if(submitting) return;
        submittingSet(true);

        socialFriendAccept(request.id, accept).then(() => {
            //mark friend request as accepted
            request.accepted = accept;
            const f = [...friendRequests];
            friendRequestsSet(f);

            //decrement request count
            props.friendRequestCountSet(props.friendRequestCount-1);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, socialFriendAcceptErrors)});
        }).finally(() => {
            submittingSet(false);
        })
    }, [friendRequests, props, submitting]);

    return <>
        <h4 className="mb-0">
            Freundschafts Anfragen {props.friendRequestCount>0 && <>
                <span className="badge rounded-pill bg-success">{props.friendRequestCount}</span>
            </>}
            <Button variant="secondary" onClick={handleOpen}>{open ? <ChevronUp/> : <ChevronDown/>}</Button>
        </h4>
        <MessageDisplay message={message}/>
        {props.friendRequestCount === 0 && <div>Du hast gerade keine offenen Anfragen. Du könntest ignorierte Anfragen haben.</div>}
        {open && <div>
            {friendRequests===null && <div>Loading...</div>}
            {friendRequests!==null && <div>
                {friendRequests.map((request: FriendRequest) => <FriendRequestDisplay key={request.id} request={request} handleRequest={handleRequest}/>)}
            </div>}
        </div>}
    </>;
}
