import React, {ReactElement} from "react";
import Button from "react-bootstrap/esm/Button";
import {Item} from "./PresentsService";
import {dateStringToString} from "../api/util";
import {PresentsHighPriorityStar, PresentsSelfClaimedText} from "./PresentsCommon";
import {getPayload} from "../api/apiConfig";

const ItemListText = (props: {listUid: number, item: Item}) => {
    const item = props.item;

    //self list: do not show anything
    const payload = getPayload();
    if(payload && props.listUid===payload.id) return item.claimedSelfDate ? <div><PresentsSelfClaimedText>Selbst Reserviert am {dateStringToString(item.claimedSelfDate)}</PresentsSelfClaimedText></div> : <></>;

    //claimed something self
    if(item.claimsCountOwn!==0) {
        return <div className="text-success">
            Bereits reserviert
        </div>;
    }
    //claim share waiting: waiting for claim admin
    if(item.claimsCountShareWait!==0) {
        return <div>
            Anfrage auf Beteiligung ausstehend
        </div>;
    }
    //nothing claimed self

    //other list: can still claim
    if(item.claimsCount===0) return <div>
        <div>Noch nicht reserviert</div>
    </div>;

    if(!item.type) {
        return <div>
            Reservierung möglich. Schon {item.claimsCount} Mal reserviert.
        </div>;
    }

    if(item.claimsCountShare!==0) {
        return <div>
            Beteiligung möglich
        </div>;
    }

    //other list: general
    if(!item.type) {
        return <div className="text-secondary">Bereits reserviert</div>;
    }

    //other list: fully claimed
    return <div><span className="border-bottom border-danger">Bereits vergeben</span></div>;
};

export const ItemDisplay = (props: {listUid: number, item: Item, itemSet: (item?: Item) => void} & React.HTMLAttributes<HTMLDivElement>): ReactElement => {
    const item = props.item;
    const want = item.priority > 0;
    return <div key={item.id}
                className={"py-1 px-2 mb-2 border rounded-3 bg-light " + (want ? 'border-success-subtle' : 'border-danger')}>
        <h5 className="d-inline-block mb-1">{item.priority===2 && <PresentsHighPriorityStar/>} {item.name}</h5>
        <Button variant="link" className="float-end p-0" onClick={() => props.itemSet(item)}>Mehr Details</Button>
        {item.claimedSelfDate && <div><b className="text-danger">Achtung!</b> Wünschende Person hat <PresentsSelfClaimedText>selbst reserviert!</PresentsSelfClaimedText></div>}
        <ItemListText listUid={props.listUid} item={item}/>
    </div>;
}