import {Button, Card} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {
    socialFriendEditFavourite,
    socialFriendEditShare,
    socialUserAnimalChange,
    socialUserDataGet,
    socialUserFriendLinkChange,
    socialUserNameChange,
    UserDataSelf,
    UserFriendLink
} from "./SocialService";
import {AxiosError, AxiosResponse} from "axios";
import {errorMessageBase, ErrorObject, getError, getPayload, WEBSITE_BASE} from "../api/apiConfig";
import {MessageDisplay, MessageState} from "../components/Message";
import {FriendRequestContainer} from "./FriendRequestContainer";
import InputControlled from "../components/InputControlled";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";
import {Link, Route, Routes} from "react-router-dom";
import useParentRoute from "../basic/useParentRoute";
import AnimalPictureDisplay from "./AnimalPictureDisplay";
import {ProfileAnimalChooser} from "./ProfileAnimalChooser";
import {presentsListGetErrors, presentsListGetWithoutItems, UserDataSettingsList} from "../presents/PresentsService";
import {Box, BoxFill, Star, StarFill} from "react-bootstrap-icons";
import {FriendListModal} from "./FriendListModal";
import QRCode from "react-qr-code";

function sortByNames(a: UserDataSettingsList,b: UserDataSettingsList) {
    return a.name.localeCompare(b.name);
}

export const Profile = (): JSX.Element => {
    const [message, messageSet] = useState<MessageState>();
    const [submitting, submittingSet] = useState(false);
    const [userData, userDataSet] = useState<UserDataSelf>();
    const payload = getPayload();

    // friends
    const [lists, listsSet] = useState<UserDataSettingsList[]>();
    const [userShareRemove, userShareRemoveSet] = useState<UserDataSettingsList>();

    //loading lists at start
    useEffect(() => {
        presentsListGetWithoutItems().then((response: AxiosResponse<UserDataSettingsList[]>) => {
            const data: UserDataSettingsList[] = response.data;
            data.sort(sortByNames);
            listsSet(data);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, presentsListGetErrors)});
        })
    }, []);

    const handleFavourite = useCallback((list: UserDataSettingsList) => {
        if(!lists || submitting) return;
        submittingSet(true);
        socialFriendEditFavourite(list.id, !list.isFavourite).then(() => {
            list.isFavourite = !list.isFavourite;
            const lCopy: UserDataSettingsList[] = [...lists];
            lCopy.sort(sortByNames); //sort again after favourite change
            listsSet(lCopy);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [lists, submitting]);

    const handleShare = useCallback((list: UserDataSettingsList) => {
        if(!lists || submitting) return;
        submittingSet(true);
        socialFriendEditShare(list.id, !list.isShare).then(() => {
            list.isShare = !list.isShare;
            const lCopy: UserDataSettingsList[] = [...lists];
            listsSet(lCopy);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [lists, submitting]);

    const parentRoute = useParentRoute().pathname
    //load data: name, link
    useEffect(() => {
        socialUserDataGet().then((response: AxiosResponse<UserDataSelf>) => {
            userDataSet(response.data)            
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        })
    }, []);

    const handleNameChange = useCallback(() => {
        if(!userData) return;
        if(submitting) return;

        submittingSet(true);
        socialUserNameChange(userData.name).then(() => {
            const uCopy: UserDataSelf = {...userData};
            userDataSet(uCopy);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [submitting, userData]);

    const handleAnimalChange = useCallback((animalId: string) => {
        if(!userData) return;
        if(submitting) return;

        submittingSet(true);
        socialUserAnimalChange(animalId).then(() => {
            const uCopy: UserDataSelf = {...userData};
            uCopy.animal = animalId;
            userDataSet(uCopy);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [submitting, userData]);

    const handleRegenerateFriendLink = useCallback(() => {
        if(!userData) return;
        if(submitting) return;

        submittingSet(true);
        socialUserFriendLinkChange().then((response: AxiosResponse<UserFriendLink>) => {
            const uCopy: UserDataSelf = {...userData};
            uCopy.friendLink = response.data.friendLink;
            userDataSet(uCopy);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [submitting, userData]);

    const friendRequestCountUpdate = useCallback((n: number) => {
        if(!userData) return;
        const u = {...userData};
        u.friendRequestsCount = n;
        userDataSet(u);
    }, [userData]);

    if(!userData) {
        return <div className="container"><AsyncLoadingAnimation width="100%" height="450px" /></div>
    }

    return  <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-4 col-lg-2 mb-3">
                        {/* <BackLink backLink="/app"/> */}
                        <Card>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><Link to={parentRoute} className="link-chevron-left">Zurück</Link></li>
                                <li className="list-group-item"><Link to="/app/profile">Dein Profil</Link></li>
                                <li className="list-group-item"><Link to="/app/profile/share">Profil Teilen</Link></li>
                            </ul>
                        </Card>
                    </div>
                    <div className="col-12 col-sm-8 col-lg-10">
                        <MessageDisplay message={message}/>
                        <Routes>
                            <Route index path="/" element={
                                <>
                                    <div className="well mb-4">
                                        <h4>Dein Name</h4>
                                        <div>Den Namen <b>{userData.name}</b> sehen deine Freunde.</div>
                                        <InputControlled className="form-control" value={userData.name} handleChange={(v: string) => userDataSet({...userData, name: v})}/>
                                        <Button variant="primary" disabled={submitting} onClick={handleNameChange}>Namen ändern</Button>
                                    </div>

                                    <div className="well">
                                        <h4>Dein Lieblingstier</h4>
                                        <div>Wähle Dein Lieblingstier aus, um dein Profil persönlicher zu gestalten.</div>
                                        <AnimalPictureDisplay animalId={userData.animal}/>
                                        <ProfileAnimalChooser animalId={userData.animal} changeAnimal={handleAnimalChange}/>
                                    </div>
                                </>} handle={{crumb: {path: "/", title: "Dein Profil"}}} />
                            <Route path="/share" element={
                                <>
                                    <div className="well mb-4">
                                        <FriendRequestContainer friendRequestCount={userData.friendRequestsCount} friendRequestCountSet={friendRequestCountUpdate} />
                                    </div>

                                    <div className="well mb-4">
                                        <h4>Freundschafts Link</h4>
                                        <div>Dein persönlicher Link (verschicken damit dich andere deine Listen sehen können).</div>
                                        <input className="form-control" type="text" value={WEBSITE_BASE + '/app/friends?link=' + userData.friendLink + "&uid=" + payload?.id} onChange={() => {}}/>

                                        <div className="mt-2">
                                            Alternativ können Deine Freunde diesen QR-Code scannen, um auf diesen Link zu kommen.
                                        </div>
                                        <div className="p-3 text-center" style={{maxWidth: "100%"}}>
                                            <QRCode className="d-inline-block" style={{maxWidth: "100%"}} fgColor="#111111" value={WEBSITE_BASE + '/app/friends?link=' + userData.friendLink + "&uid=" + payload?.id}
                                                    size={256}/>
                                        </div>

                                        <div className="text-muted">
                                            Du kannst deinen Link neu generieren lassen, falls du Anfragen von unbekannten Personen bekommst.
                                            Der alte Link (und QR-Code) wird dann nicht mehr funktionieren.
                                            <Button variant="link" className="d-inline text-secondary p-0 ps-1 align-baseline" disabled={submitting} onClick={handleRegenerateFriendLink}>Link neu generieren</Button>
                                        </div>
                                    </div>

                                    <div className="well">
                                        <h4>Freunde</h4>
                                        <FriendListModal user={userShareRemove} userSet={userShareRemoveSet} handleShareRemove={handleShare}/>
                                        {lists ? <table className="table">
                                            <thead><tr>
                                                <td>Name</td>
                                                <td>Favorit<br/>(In Freundeslisten zuerst angezeigt)</td>
                                                <td>Darf sich Geschenke mit Dir wünschen<br/>(z.B. "Gemeinsames Geschenk mit Person B")</td>
                                                <td>Tier</td>
                                            </tr></thead>
                                            <tbody>
                                            {lists.map((user: UserDataSettingsList) => <tr key={user.id}>
                                                <td className="align-middle">{user.name}</td>
                                                <td className="align-middle">{user.isFavourite ? 'Favorit':'Kein Favorit'}<Button variant="outline-light" className={"fs-4 " + (user.isFavourite ? 'text-warning':'text-secondary')} onClick={() => handleFavourite(user)}>{user.isFavourite ? <StarFill className="align-baseline"/> : <Star className="align-baseline"/>}</Button></td>
                                                <td className="align-middle">{user.isShare ? 'Ja':'Nein'}<Button variant="outline-light" className={"fs-4 " + (user.isShare ? 'text-warning':'text-secondary')} onClick={() => {
                                                    // remove share
                                                    if(user.isShare) userShareRemoveSet(user);
                                                    // add share
                                                    else handleShare(user)
                                                }}>{user.isShare ? <BoxFill className="align-baseline"/> : <Box className="align-baseline"/>}</Button></td>
                                                <td className="align-middle"><AnimalPictureDisplay animalId={user.animal} className="h-100" style={{maxHeight: "60px"}}/></td>
                                            </tr>)}
                                            </tbody>
                                        </table> : <AsyncLoadingAnimation width="100%" height="300px"/>}
                                    </div>
                                </>} handle={{crumb: {path: "/share", title: "Teilen"}}} />
                        </Routes>
                    </div>
                </div>
            </div>
}