import {Button} from "react-bootstrap";
import {FriendRequest} from "./SocialService";

export const FriendRequestDisplay = (props: {request: FriendRequest, handleRequest: (r: FriendRequest, a: boolean) => void}): JSX.Element => {
    return <div className="bg-light p-2">
        <div>Name: <b>{props.request.name}</b></div>
        <div className="text-muted">ID: #{props.request.id}</div>
        {props.request.accepted && <div className="text-success">Anfrage akzeptiert</div>}
        {props.request.accepted===false && <>
            <div className="mb-2">
                <span className="border-bottom border-2 border-warning">Anfrage Ignoriert.</span>
            </div>
            <Button variant="primary" onClick={() => props.handleRequest(props.request, true)}>Anfrage doch akzeptieren</Button>
        </>}
        {props.request.accepted===null && <>
            <Button variant="primary" onClick={() => props.handleRequest(props.request, true)}>Anfrage akzeptieren</Button>
            <Button variant="warning" onClick={() => props.handleRequest(props.request, false)}>Anfrage ignorieren</Button>
        </>}
    </div>;
}