import Cookies from "universal-cookie/es6";
import jwtDecode from "jwt-decode";

export const WEBSITE_BASE = process.env.REACT_APP_WEBSITE_URL;
export const API_BASE = process.env.REACT_APP_API_URL;

export const C = new Cookies();

export const API_CONFIG = () => {
    return {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "_token": getToken()
        }
    }
};

export function setToken(token: string) {
    C.set("_token", token, {path: '/'});
}

export function removeToken() {
    C.set("_token", "", {path: '/', expires: new Date()});
}

export function getToken(): string {
    return C.get("_token");
}

export interface Payload {
    id: number;
}

export function getPayload(): Payload {
    const jwt = C.get("_token");
    //if(!jwt) return undefined;
    return jwtDecode(jwt);
}

export function isLoggedIn(): boolean {
    return C.get("_token")?.length > 0;
}

export const errorMessageBase: string[] = [
    "Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "Sie sind nicht eingeloggt. Bitte loggen Sie sich ein.",
    "Es fehlen Eingabe Parameter. Dies ist ein Programmier Fehler. Bitte schreiben Sie unserem Support.", //reserved for generic message
    "",
];

export interface ErrorObject {
    error?: string,
    errorCode?: number
}

export const getError = (errorObject: ErrorObject | undefined, errorMessages: string[]) => {
    if (errorObject) {
        if (errorObject.errorCode !== undefined && errorObject.errorCode < errorMessages.length) return errorMessages[errorObject.errorCode];
        if (errorObject.error) return errorObject.error;
    }
    return "Error";
}