import {Button, Card, Form} from "react-bootstrap";
import React, {FormEvent, useCallback, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {EnvelopeAt} from "react-bootstrap-icons";
import InputControlled from "../components/InputControlled";
import {Link} from "react-router-dom";
import {authForgotRequest} from "./AuthenticationService";
import {AxiosError} from "axios";
import {errorMessageBase, ErrorObject, getError} from "../api/apiConfig";

export const ForgotPage = (): JSX.Element => {
    const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [stage, stageSet] = useState(0);
    const [email, emailSet] = useState('');

    const handleEmail = useCallback((event: FormEvent) => {
        event.preventDefault();
        if(submitting) return;

        submittingSet(true);
        authForgotRequest(email).then(() => {
            stageSet(1);
            messageSet(undefined);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [email, submitting]);

    return <div className="container d-flex align-items-center justify-content-center" style={{height: "95vh"}}>
        <Card style={{maxWidth: "400px"}} className="w-100">
            {/*Input email*/ stage===0 && <>
                <Form onSubmit={handleEmail}>
                    <Card.Body>
                        <Card.Title>Passwort zurücksetzen</Card.Title>
                        <MessageDisplay message={message}/>
                        <h6>Bitte gib Deine Email ein. Wir schicken Dir einen Link zum Passwort zurücksetzen.</h6>

                        <Form.Group>
                            <Form.Label><EnvelopeAt/> Account Email</Form.Label>
                            <InputControlled value={email} name="email" className="form-control" handleChange={(v: string) => emailSet(v)}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex py-2 flex-column align-items-center'>
                            <Button variant="primary" type="submit" disabled={submitting || !email.includes('@')}>Sicherheitscode anfragen</Button>
                        </div>
                        <div className="d-flex py-2 flex-column align-items-center small">
                            <p className='text-muted mb-0'>Du weißt Dein Passwort wieder?</p>
                            <Link to="/app/login">Zurück zum Login</Link>
                        </div>
                    </Card.Footer>
                </Form>
            </>}
            {/*Open email message*/ stage===1 && <>
                <Card.Body>
                    <Card.Title>Passwort zurücksetzen</Card.Title>

                    <h5><span className="text-success">Eine Email wurde an Dich verschickt!</span></h5>
                    Bitte folge den Anweisungen in der Email. Bitte schau auch in Deinem Spam-Ordner.
                </Card.Body>
            </>}
        </Card>
    </div>;
};