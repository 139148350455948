import axios, {AxiosResponse} from "axios";
import {API_BASE, API_CONFIG, errorMessageBase} from "../api/apiConfig";

const API = API_BASE + "/social"

export interface UserDataSelf {
    name: string;
    friendLink: string;
    friendRequestsCount: number;
    animal: string;
}
export interface FriendRequest {
    id: number;
    name: string;
    accepted?: boolean;
}
export interface UserDataNameId {
    id: number;
    name: string;
}
export interface UserDataNameIdAnimal extends UserDataNameId {
    animal: string;
}
export interface UserFriendLink {
    friendLink: string;
}

export function socialUserDataGet(): Promise<AxiosResponse<UserDataSelf>> {
    return axios.get(API+"/userData.php", API_CONFIG());
}

export function socialUserFriendLinkChange(): Promise<AxiosResponse<UserFriendLink>> {
    return axios.put(API+"/userData.php", {}, API_CONFIG());
}

export function socialUserNameChange(name: string): Promise<AxiosResponse<void>> {
    return axios.post(API+"/userData.php", {name}, API_CONFIG());
}

export function socialUserPasswordChange(password: string): Promise<AxiosResponse<void>> {
    return axios.post(API+"/userData.php", {password}, API_CONFIG());
}

export function socialUserAnimalChange(animal: string): Promise<AxiosResponse<void>> {
    return axios.post(API+"/userData.php", {animal}, API_CONFIG());
}

export function socialFriendUserDataGet(friendLink: string): Promise<AxiosResponse<UserDataNameIdAnimal>> {
    return axios.get(API+"/friend.php?link=" + friendLink, API_CONFIG());
}

export function socialFriendRequestsGet(): Promise<AxiosResponse<FriendRequest[]>> {
    return axios.get(API+"/friend.php", API_CONFIG());
}

export const socialFriendBefriendErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, wir konnten keine Person mit diesem Link finden. Bitte fragen Sie die Person erneut nach ihrem Link.",
    "Sie haben bereits dieser Person eine Anfrage geschickt.",
    "Sie sind bereits mit dieser Person befreundet.",
]);
export function socialFriendBefriend(uid: number, friendLink: string): Promise<AxiosResponse<void>> {
    return axios.post(API+"/friend.php", {uid, link: friendLink}, API_CONFIG());
}

export const socialFriendAcceptErrors: string[] = errorMessageBase.concat([
    "Sie sind bereits mit dieser Person befreundet.",
]);
export function socialFriendAccept(uid: number, accept: boolean): Promise<AxiosResponse<void>> {
    return axios.put(API+"/friend.php", {uid, accept: accept?1:0}, API_CONFIG());
}

export function socialFriendEditFavourite(uid: number, favourite: boolean): Promise<AxiosResponse<void>> {
    return axios.patch(API+"/friend.php", {uid, favourite: favourite?1:0}, API_CONFIG());
}

export function socialFriendEditShare(uid: number, share: boolean): Promise<AxiosResponse<void>> {
    return axios.patch(API+"/friend.php", {uid, share: share?1:0}, API_CONFIG());
}