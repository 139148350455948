import {AxiosError, AxiosResponse} from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {ArrowLeftSquare, ChevronLeft} from "react-bootstrap-icons";
import {Link, useParams} from "react-router-dom";
import {ErrorObject, getError, getPayload} from "../api/apiConfig";
import NotFoundPage from "../basic/NotFoundPage";
import {MessageDisplay, MessageState} from "../components/Message";
import {ItemDisplay} from "./ItemDisplay";
import {ItemModal} from "./ItemModal";
import {Item, itemSort, ItemWantDont, List, presentsItemsGet, presentsItemsGetErrors} from "./PresentsService";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";
import AnimalPictureDisplay, {AnimalPictureDisplayLoad} from "../social/AnimalPictureDisplay";

const ItemsList = (props: {listUid: number, items: Item[], itemSet: (item?: Item) => void} & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
    return <div className={"w-100 px-3 " + props.className}>
        {/*existing items*/ props.items.map((item: Item) => <ItemDisplay key={item.id} listUid={props.listUid} item={item} itemSet={props.itemSet} />)}
    </div>;
}

const itemSearch = (id: number) => {
    return (value: Item): boolean => value.id === id;
}

export default function ListController(): JSX.Element {
    const listIdString = useParams().listid;
    const listId = listIdString ? +listIdString : undefined;

    const [items, itemsSet] = useState<ItemWantDont>(); //items inside list
    const [item, itemSet] = useState<Item>(); //selected item to edit
    const [message, messageSet] = useState<MessageState>();

    const payload = getPayload();

    //loading items on list change
    useEffect(() => {
        if(!listId) return;

        presentsItemsGet(listId).then((response: AxiosResponse<List>) => {
            const list: List = response.data;
            const itemWantDont: ItemWantDont = {
                name: list.name,
                animal: list.animal,
                sharePeople: list.sharePeople,
                want: [], general: [], dont: []
            };

            //add to want/don't
            response.data.items.forEach((item: Item) => {
                //remove self from shared list
                // item.sharedPeople = item.sharedPeople.filter((uInfo: UserDataNameId) => uInfo.id!==payload?.id);

                //put into lists
                if(item.priority > 0) {
                    if(item.type) itemWantDont.want.push(item);
                    else itemWantDont.general.push(item);
                }
                else itemWantDont.dont.push(item);
            });
            //sort want after priority
            itemWantDont.want.sort(itemSort);
            itemWantDont.general.sort(itemSort);
            itemWantDont.dont.sort(itemSort);
            itemsSet(itemWantDont);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, presentsItemsGetErrors)});
        })
    }, [listId, payload?.id])

    const itemNew = useCallback(() => {
        itemSet({name: 'Geschenk', priority: 1, description: '', id: -1, creatorId: payload ? payload.id : -1, type: true, sharedPeople: [],
            claimsCountOwn:0, claimsCountShare: 0, claimsCountShareWait:0, claimsCount: 0
        });
    }, [payload]);

    const handleItemChange = useCallback((itemChanged: Item, id: number) => {
        if(!itemChanged || !items) return;
        const itemsCopy = {...items};
        const shouldList = itemChanged.priority === 0 ? itemsCopy.dont : (itemChanged.type ? itemsCopy.want : itemsCopy.general);

        //new item: update id
        if(itemChanged.id === -1) {
            itemChanged.id = id;
            shouldList.push(itemChanged);
            shouldList.sort(itemSort);
            itemsSet(itemsCopy);
            return;
        }
        //update item: put into correct list, sort again
        const index = shouldList.findIndex(itemSearch(itemChanged.id));
        if(index !== -1) {
            //remove old version
            shouldList.splice(index, 1);
        } else {
            //in wrong list - remove
            const wrongList = itemChanged.priority !== 0 ? itemsCopy.dont : itemsCopy.want;
            const indexWrong = wrongList.findIndex(itemSearch(itemChanged.id));
            if(index !== -1) {
                wrongList.splice(indexWrong, 1);
            }
        }

        //add to correct list
        shouldList.push(itemChanged);
        shouldList.sort(itemSort);
        itemsSet(itemsCopy);
    }, [items]);

    const handleRerender = useCallback(() => {
        if(!items) return;
        const i: ItemWantDont = {...items};
        itemsSet(i);
    }, [items]);

    if (!listId)
        return <NotFoundPage />

    if(!items) {
        return <div className="container">
            <MessageDisplay message={message}/>
            <Link to="../"><ArrowLeftSquare className="text-white me-1"/> Zurück</Link>
            <div className="text-center mb-2">
                <div className="d-inline-block">
                    <AsyncLoadingAnimation width="300px" height="300px"/>
                </div>
            </div>

            <AsyncLoadingAnimation className="mb-2" width="100%" height="60px"/>
            <AsyncLoadingAnimation className="mb-2" width="100%" height="60px"/>
            <AsyncLoadingAnimation width="100%" height="60px"/>
        </div>;
    }

    const selfList = listId===payload?.id;

    //items display
    return <div className="container">
        <MessageDisplay message={message}/>
        <ItemModal userInfo={items} listUid={listId} item={item} itemSet={itemSet} itemNewAdd={handleItemChange} listRerender={handleRerender}/>

        {/*animal*/}
        <div className="text-center mb-2">
            <h4 className="mb-2 float-start">
                <Link to="../"><span className="align-text-bottom pb-1 d-inline-block"><ChevronLeft/></span> Zurück</Link>
            </h4>
            <div className="d-inline-block">
                <h3 className="mb-0">{selfList ? <span>Deine Liste</span> : <span>Liste von <b>{items.name}</b></span>}</h3>
                <div>{selfList ? <AnimalPictureDisplayLoad/> : <AnimalPictureDisplay animalId={items.animal}/> }</div>
            </div>
        </div>

        {/*add item if can*/ selfList &&
            <Button variant="primary" className="w-100 mb-3" onClick={itemNew}>Neues Geschenk</Button>}

        {/*empty list*/items.want.length === 0 && items.general.length === 0 && items.dont.length === 0 && <div>Diese Liste ist leer.</div>}

        {/*want items*/ items.want.length > 0 && <>
            <h4 className="text-black mb-1">Gewünschtes</h4>
            <ItemsList listUid={listId} items={items.want} itemSet={itemSet} className="mb-3"/>
        </>}

        {/*general items*/ items.general.length > 0 && <>
            <h4 className="text-black mb-1">Generelle Wünsche</h4>
            <ItemsList listUid={listId} items={items.general} itemSet={itemSet} className="mb-3"/>
        </>}

        {/*dont want items*/ items.dont.length > 0 && <>
            <h4 className="text-black mb-1">Nicht Erwünschtes</h4>
            <ItemsList listUid={listId} items={items.dont} itemSet={itemSet} className="mb-3"/>
        </>}
    </div>;
}