import React, {useEffect, useState} from "react";
import {socialUserDataGet, UserDataSelf} from "./SocialService";
import {AxiosResponse} from "axios";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";

export interface AnimalPicture {
    animalName: string;
    id: string;
    attribution?: JSX.Element;
}

export const AttributionLink = (props: {href: string, text: string}): JSX.Element => {
    return <a className="text-muted text-decoration-underline d-block" style={{fontSize: '7px'}} href={props.href}>{props.text}</a>;
};

//list of all possible images
export const animalsList: AnimalPicture[] = [
    {animalName: 'Katze', id: 'Cat'},
    {animalName: 'Katze', id: 'Cat1', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Katze', id: 'Cat2', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Katze', id: 'Cat3', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Katze', id: 'Cat4', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},

    {animalName: 'Hund', id: 'Dog', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Hund', id: 'Dog2', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},

    {animalName: 'Schmetterling', id: 'Butterfly', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Schmetterling', id: 'Butterfly2', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Schmetterling', id: 'Butterfly3', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},

    {animalName: 'Elefant', id: 'Elephant', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Pferd', id: 'Horse', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Maus', id: 'Mouse', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Ratte', id: 'Rat', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Schildkröte', id: 'Turtle', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Schildkröte', id: 'Turtle2', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Hase', id: 'Rabbit', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},

    {animalName: 'Delphin', id: 'Dolphin', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Eule', id: 'Owl', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Nashorn', id: 'Rhino', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Hai', id: 'Shark', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Wal', id: 'Whale', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Wal', id: 'Whale2', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Zebra', id: 'Zebra', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},

    {animalName: 'Affe', id: 'Monkey', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Affe', id: 'Monkey2', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},

    {animalName: 'Erdmännchen', id: 'Meerkat', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Schaf', id: 'Sheep', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Huhn', id: 'Chicken', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Giraffe', id: 'Giraffe', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Löwe', id: 'Lion', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
    {animalName: 'Tiger', id: 'Tiger', attribution: <AttributionLink href="https://www.freepik.com" text="Designed by rawpixel.com / Freepik"/>},
];

//list of groups (e.g. Cat could have different picture styles OR just different cats)
export const animalsGroup: Record<string, AnimalPicture[]> = {
    //'Cat': ['Cat', 'Cat1', 'Cat2', 'Cat3', 'Cat4'],
    //'Dog': ['Dog', 'Dog2']
};
export const animalsGroupList: string[] = [];
//build animals groups
function assignAnimals(animal: AnimalPicture) {
    if(!animalsGroup[animal.animalName]) {
        animalsGroupList.push(animal.animalName);
        animalsGroup[animal.animalName] = [animal];
    }
    else animalsGroup[animal.animalName].push(animal);
}
animalsList.forEach(assignAnimals)
animalsGroupList.sort()
animalsList.sort((a: AnimalPicture,b: AnimalPicture) => a.animalName.localeCompare(b.animalName));

export function animalGet(animalId: string): AnimalPicture | undefined {
    const length = animalsList.length;
    for(let i=0; i<length; ++i) {
        if(animalsList[i].id === animalId) return animalsList[i];
    }
    return undefined;
}

function AnimalPictureFilename(animalId: string): string {
    return '/assets/img/animals/' + animalId + '.webp';
}

export function AnimalPictureDisplayLoad(): JSX.Element {
    const [userData, userDataSet] = useState<UserDataSelf>();

    //load data: name, link
    useEffect(() => {
        socialUserDataGet().then((response: AxiosResponse<UserDataSelf>) => {
            const uData: UserDataSelf = response.data;
            const img = new Image();
            img.src = AnimalPictureFilename(uData.animal);
            img.onload = () => {
                userDataSet(uData);
            };
        })
    }, []);

    const animal = userData!==undefined ? animalGet(userData.animal) : undefined;
    if(animal===undefined) {
        return <AsyncLoadingAnimation width="100%" height="300px"/>;
    }

    return <div className="p-1">
        <div style={{maxWidth: '300px'}}><img className="w-100" src={AnimalPictureFilename(animal.id)} alt={animal.animalName} /></div>
        {animal.attribution}
    </div>;
}

export interface AnimalPictureDisplayProps {
    className?: string;
    style?: React.CSSProperties;
}

export interface AnimalPictureDisplayProps1 extends AnimalPictureDisplayProps {
    animalId: string,
    animalPicture?: never
}
export interface AnimalPictureDisplayProps2 extends AnimalPictureDisplayProps {
    animalId?: never,
    animalPicture: AnimalPicture
}

export default function AnimalPictureDisplay(props: AnimalPictureDisplayProps1 | AnimalPictureDisplayProps2): JSX.Element {
    const [loaded, loadedSet] = useState(false);
    const animal = props.animalId ? animalGet(props.animalId) : props.animalPicture;

    // preload image
    useEffect(() => {
        if(!animal) return;
        const img = new Image();
        img.src = AnimalPictureFilename(animal.id);
        img.onload = () => {
            loadedSet(true);
        };
    }, [animal]);

    // loading animation
    if(animal===undefined || !loaded) {
        return <AsyncLoadingAnimation width="100%" height="150px"/>;
    }

    // image display
    return <div className="p-1">
        <img className={props.className ? props.className : "w-100"}
             style={props.style ? props.style : {maxWidth: '300px'}}
             src={AnimalPictureFilename(animal.id)} alt={animal.animalName} />
        {animal.attribution}
    </div>;
}