import {ReactElement} from "react";

export const Footer = (): ReactElement => {
    return <footer id="footer" className="footer">
        <div className="footer-top bg-white">
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-info">
                        <a href="/" className="logo d-flex align-items-center">
                            <img src="/assets/icons/android/android-icon-192x192.png" alt=""/>
                            <span>Presently</span>
                        </a>
                        <h5>
                            Gemeinsam Wünsche erfüllen
                        </h5>
                        <p>Wir bieten eine einzigartige Plattform für Familien und Befreundete, um sich gegenseitig zu beschenken. Wir helfen Ihnen, sich zu organisieren und Ihren Liebsten zu zeigen, was Sie sich wünschen.</p>
                    </div>
                    {/*<div className="col-lg-2 col-6 footer-links">
                        <h4>Useful Links</h4>
                    </div>
                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Services</h4>
                    </div>*/}
                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                        <p>
                            Leonardo Wang<br/>
                            Obere Augartenstraße 12-14/1/12<br/>
                            1020 Wien<br/>
                            E-Mail: <a href="mailto:support@schenkschatzi.at">support@schenkschatzi.at</a><br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                {new Date().getFullYear()} &copy; Copyright <strong>Schenkschatzi</strong>.<br/>
                All Rights Reserved
            </div>
        </div>
    </footer>;
};