import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Button, Card} from "react-bootstrap";
import {ChevronRight, Star, StarFill} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import {ListItem, ListOverview, listOverviewSort, presentsListGet, presentsListGetErrors} from "./PresentsService";
import AnimalPictureDisplay from "../social/AnimalPictureDisplay";
import {MessageDisplay, MessageState} from "../components/Message";
import {AxiosError, AxiosResponse} from "axios";
import {errorMessageBase, ErrorObject, getError} from "../api/apiConfig";
import {socialFriendEditFavourite} from "../social/SocialService";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";

function ListFirstThreeImplode(list: ListItem[]): string {
    if(list.length <= 2) return list.map((item: ListItem) => item.name).join(", ");
    return list.slice(0, 2).map((item: ListItem) => item.name).join(", ") + "...";
}

export const ListsList = (): ReactElement => {
    const [lists, listsSet] = useState<ListOverview[]>(); //all lists visible for this user
    const [message, messageSet] = useState<MessageState>();
    const [submitting, submittingSet] = useState(false);

    //loading lists at start
    useEffect(() => {
        presentsListGet().then((response: AxiosResponse<ListOverview[]>) => {
            const data: ListOverview[] = response.data;
            data.sort(listOverviewSort);
            listsSet(data);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, presentsListGetErrors)});
        })
    }, []);

    const handleFavourite = useCallback((list: ListOverview) => {
        if(!lists || submitting) return;
        submittingSet(true);
        socialFriendEditFavourite(list.user.id, !list.user.isFavourite).then(() => {
            list.user.isFavourite = !list.user.isFavourite;
            const lCopy: ListOverview[] = [...lists];
            lCopy.sort(listOverviewSort); //sort again after favourite change
            listsSet(lCopy);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [lists, submitting]);

    return <div className="row">
        <MessageDisplay message={message}/>

        {/*Friends list*/ lists!==undefined ? lists.map((list: ListOverview) => {
            const claimedSomething = list.itemsReserved.length>0;
            return <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-1" key={list.user.id}>
                <div className="well-sm m-1">
                    <Card.Body className="d-flex flex-column align-items-center">
                        <div className="mb-2 mt-2 w-100"><AnimalPictureDisplay animalId={list.user.animal}/></div>
                        <Button variant="outline-light" className={"border-0 position-absolute end-0 top-0 fs-4 pb-2 " + (list.user.isFavourite ? 'text-warning':'text-secondary')} onClick={() => handleFavourite(list)}>{list.user.isFavourite ? <StarFill/> : <Star/>}</Button>

                        <Card.Title>
                            <span className={claimedSomething ? 'bg-success' : "bg-danger"} style={{width: "0.75rem", height: "0.75rem", display: "inline-block", borderRadius: "100%", top: "-1px", position: "relative", marginRight: "0.3rem"}}></span>
                            {list.user.name}
                        </Card.Title>

                        <Link to={""+list.user.id} className="">Liste anzeigen <ChevronRight /></Link>
                        {claimedSomething && <p className="mb-0">{ListFirstThreeImplode(list.itemsReserved)}</p>}
                    </Card.Body>
                </div>
            </div>
        }) : <>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-1">
                <div className="m-1">
                    <AsyncLoadingAnimation width="100%" height="200px"/>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-1">
                <div className="m-1">
                    <AsyncLoadingAnimation width="100%" height="200px"/>
                </div>
            </div>
        </>}
        {/*new friend link*/}
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-1">
            <div className="bg-light h-100 well m-1">
                <Card.Body className="d-flex flex-column align-items-center">
                    <div className="fs-1">+</div>
                    <Link to="/app/profile/share">Füge Freund*innen hinzu <ChevronRight /></Link>
                </Card.Body>
            </div>
        </div>
    </div>;
}