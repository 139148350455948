import React from "react";
import {getPayload, isLoggedIn} from "../api/apiConfig";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import NotLoggedInPage from "../basic/NotLoggedInPage";
import {ListsList} from "./PresentsListList";
import ListController from "./ListController";
import {ChevronRight} from "react-bootstrap-icons";

export default function PresentsPage() {
    const navigate = useNavigate();

    //not logged in
    if (!isLoggedIn()) {
        navigate("/login");
        return <NotLoggedInPage/>;
    }

    const payload = getPayload();

    //lists display
    return <div className="container">
        <Routes>
            <Route index path="/" element={<>
                {payload && <h5><Link to={"" + payload.id}>Hier gehts zu Deiner Liste <ChevronRight /></Link></h5>}

                <h4 className="mb-1">Freunde</h4>
                <ListsList/>
            </>}
            />
            <Route path="/:listid" element={<ListController/>}
                   handle={{crumb: {path: "/:listid", title: "Eine Liste"}}}/>
        </Routes>

    </div>;
}