export function indexGet<T>(list: T[], predicate: (item: T) => boolean): T | undefined {
    for(let i=list.length-1; i>=0; --i) {
        if(predicate(list[i])) return list[i];
    }
    return undefined;
}

export function dateStringToDate(dateString: string): Date {
    return new Date(Date.parse(dateString.replace(" ", "T")));
}

export function dateStringToString(dateString: string) {
    const d = dateStringToDate(dateString);
    return d.getDate() + "." + (d.getMonth()+1) + "." + d.getFullYear();
}

export function dateStringToTimeString(dateString: string) {
    const d = dateStringToDate(dateString);
    return dateStringToString(dateString) + " " + d.getHours() + ":" + d.getMinutes();
}