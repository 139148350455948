import { useCallback, useEffect, useState } from "react"

type ScrollEventListenerOptions = {
    scrollX?: number,
    scrollY?: number,
    scrollXDirection?: "viewBoxToLeft" | "viewBoxToRight",
    scrollYDirection?: "viewBoxToSky" | "viewBoxToEarth",
}

type ScrollEventListener = {
    triggering: boolean,
    changed: boolean
}

export default function useScrollEventListener(options: ScrollEventListenerOptions): ScrollEventListener {

    const [scrollEvent, setScrollEvent] = useState<ScrollEventListener>({triggering: false, changed: false})

    const scrollListener = useCallback(() => {        

        let matchScrollX = true;
        let matchScrollY = true;

        if (options.scrollX) {
            matchScrollX = options.scrollXDirection === "viewBoxToRight" ? window.scrollX < options.scrollX : window.scrollX > options.scrollX
        }

        if (options.scrollY) {
            matchScrollY = options.scrollYDirection === "viewBoxToEarth" ? window.scrollY < options.scrollY : window.scrollY > options.scrollY
        }

        setScrollEvent({triggering: matchScrollX && matchScrollY, changed: matchScrollX && matchScrollY !== scrollEvent.triggering})
    }, [options.scrollX, options.scrollXDirection, options.scrollY, options.scrollYDirection, scrollEvent.triggering])
    
    useEffect(() => {
        window.addEventListener('scroll', scrollListener, {passive: true})
        return () => window.removeEventListener('scroll', scrollListener)
    }, [scrollListener])

    return scrollEvent
}