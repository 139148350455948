import axios, {AxiosResponse} from "axios";
import {API_BASE, API_CONFIG, errorMessageBase} from "../api/apiConfig";

const API = API_BASE + "/authentication"

export interface UserLoginInfo {
    email: string;
    password: string;
}

export interface UserRegisterInfo extends UserLoginInfo {
    password2: string;
}

export const authRegisterErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, diese E-Mail scheint nicht gültig zu sein. Eine gültige E-Mail muss ein @-Zeichen enthalten.",
    "Entschuldigung, diese E-Mail scheint schon vergeben zu sein. Falls Sie Ihr Passwort vergessen haben, gehen Sie bitte auf <a href='/forgot'>Passwort vergessen</a>.",
    "Entschuldigung, Ihr Passwort muss mindestens 6 Zeichen haben.",
]);

export function authRegister(info: UserLoginInfo): Promise<AxiosResponse<string>> {
    return axios.post(API+"/register.php", info, API_CONFIG());
}

export const authLoginErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, wir konnten keine Übereinstimmung für die von Ihnen eingegebene E-Mail-Adresse und das Passwort finden. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut."
]);

export function authLogin(info: UserLoginInfo): Promise<AxiosResponse<string>> {
    return axios.post(API+"/login.php", info, API_CONFIG());
}

export const authEmailVerifyErrors: string[] = errorMessageBase.concat([
    "Entschuldigung, der Sicherheitscode scheint falsch zu sein. Bitte überprüfen Sie den Code und versuchen Sie es erneut."
]);

export function authEmailVerify(link: string, code: string): Promise<AxiosResponse<void>> {
    return axios.get(API+"/email-confirm.php?link=" + link + "&code=" + code, API_CONFIG());
}

export function authForgotRequest(email: string): Promise<AxiosResponse<void>> {
    return axios.post(API+"/forgot.php?action=email", {email}, API_CONFIG());
}

export function authForgotCode(uid: number, link: string, code: string): Promise<AxiosResponse<string>> {
    return axios.post(API+"/forgot.php?action=code", {uid, link, code}, API_CONFIG());
}

//export const eventList = useApiData<EventDateListDto[], string>(EventEndpoint.GET.eventDates_list);
//export const userApiLogin = API_BASE + ""; //replaces EventEndpoint.GET.eventDates_list

/*export function emailBasicSend(email: EmailBasic): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE+"/emails/"+email.toGroup+(email.id?"/"+email.id:""), email, {headers: {Authorization: "Bearer "+getToken()}})
}*/