import React from "react";
import {MessageDisplay} from "../components/Message";
import {Link} from "react-router-dom";

export default function NotLoggedInPage() {
    return (
        <div className="container">
            <h1>Du wirst weitergeleitet</h1>
            <MessageDisplay message={{
                type: "warning",
                message: "Du bist nicht eingeloggt. Um diese Seite zu sehen, " +
                    "musst Du eingeloggt sein. Du wirst automatisch weitergeleitet."
            }}/>
            <Link to="/" className="text-center d-block">Wirst Du nicht automatisch weitergeleitet? Hier gehts zur
                Startseite.</Link>
        </div>);
};