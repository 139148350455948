import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {
    socialFriendBefriend,
    socialFriendBefriendErrors,
    socialFriendUserDataGet,
    UserDataNameIdAnimal
} from "./SocialService";
import {AxiosError, AxiosResponse} from "axios";
import {errorMessageBase, ErrorObject, getError, getPayload} from "../api/apiConfig";
import {MessageDisplay, MessageState} from "../components/Message";
import {Button} from "react-bootstrap";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";
import AnimalPictureDisplay from "./AnimalPictureDisplay";

export const FriendLinkPage = (): ReactElement => {
    const [searchParams] = useSearchParams();

    const [message, messageSet] = useState<MessageState>();
    const [submitting, submittingSet] = useState(false);
    const [isFriend, isFriendSet] = useState(false);
    const [userData, userDataSet] = useState<UserDataNameIdAnimal>();

    //load data: name, link
    useEffect(() => {
        const link = searchParams.get('link');
        if(!link) {
            // messageSet({type: "danger", message: "Entschuldigung, dieser Link scheint nicht zu funktionieren."})
            return;
        }

        socialFriendUserDataGet(link).then((response: AxiosResponse<UserDataNameIdAnimal>) => {
            userDataSet(response.data)
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        })
    }, [searchParams]);

    const handleBefriend = useCallback(() => {
        if(submitting) return;
        const link = searchParams.get('link');
        const uid = searchParams.get('uid');
        if(!link || !uid) return;

        submittingSet(true);
        socialFriendBefriend(+uid, link).then(() => {
            isFriendSet(true);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, socialFriendBefriendErrors)});
            submittingSet(false);
        })
    }, [searchParams, submitting]);

    const uid = searchParams.get('uid');

    //loading
    if(!uid || !userData) {
        return <div className="container">
            <MessageDisplay message={message}/>
            <AsyncLoadingAnimation width="100%" height="500px"/>
        </div>
    }

    //self link
    const payload = getPayload();
    if(payload && +uid === payload.id) {
        return <div className="container">
            <MessageDisplay message={message}/>
            <h4>Dies ist dein eigener Freundschafts link.</h4>
            <div>Hier können andere Personen Dir Freundschaftsanfragen schicken.</div>
            <div className="my-2"><AnimalPictureDisplay animalId={userData.animal}/></div>
            <div className="mb-3"><Button variant="primary" onClick={() => handleBefriend()} disabled={true}>{userData.name} befreunden</Button></div>
        </div>
    }

    //normal display
    return <div className="container">
        <MessageDisplay message={message}/>
        <h4>Willst du <b>{userData.name}</b> <span>(ID #{userData.id})</span> befreunden?</h4>
        <AnimalPictureDisplay animalId={userData.animal}/>
        <div className="my-3">
            Durch das Befreunden wirst du die Listen von <b>{userData.name}</b> sehen und <b>{userData.name}</b> kann deine Listen sehen.<br/>
            Du solltest <b>{userData.name}</b> in echt kennen.
        </div>
        {!isFriend ? <>
                <div className="mb-3"><Button variant="primary" onClick={() => handleBefriend()} disabled={submitting}>{userData.name} befreunden</Button></div>
            </>
            : <div>
                <div className="text-success">Du hast <b>{userData.name}</b> eine Anfrage geschickt. Nachdem <b>{userData.name}</b> die Anfrage akzeptiert hat, wirst du deren Listen sehen.</div>
                <Link to='/app/list'>Zur Geschenkeliste</Link>
            </div>}
    </div>;
};