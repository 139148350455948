import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import AnimalPictureDisplay, {
    animalGet,
    AnimalPicture,
    animalsGroup,
    animalsGroupList,
    animalsList
} from "./AnimalPictureDisplay";

function AnimalChooserDisplay(props: {animal: AnimalPicture, animalId: string, changeAnimal: (aId: string) => void}) {
    const animal: AnimalPicture = props.animal;
    return <div key={animal.id} className="col-sm-6 col-lg-4 col-xl-3 p-2">
        <div className="bg-light rounded-4 p-3 border border-dark-subtle border-1">
            <div className="text-muted small text-end">Bild id: {animal.id}</div>
            <AnimalPictureDisplay animalPicture={animal}/>
            <div className="text-center">
                {props.animalId===animal.id ? <div className="text-primary">Ausgewählt</div> : <Button variant="primary" onClick={() => props.changeAnimal(animal.id)}>Auswählen</Button>}
            </div>
        </div>
    </div>;
}

export const ProfileAnimalChooser = (props: {animalId: string, changeAnimal: (id: string) => void}): JSX.Element => {
    const [animalName, animalNameSet] = useState("");

    //set animal name on start / on animalId change
    useEffect(() => {
        const a = animalGet(props.animalId);
        if(a===undefined) return;
        animalNameSet(a.animalName);
    }, [props.animalId])

    if(!animalName) return <></>;

    const list: AnimalPicture[] = animalName==='all-show' ? animalsList : animalsGroup[animalName];

    return <>
        {/*Choose animal*/}
        <h4 className="mt-3">Wähle das Tier</h4>
        <select className="form-select" value={animalName} onChange={event => animalNameSet(event.target.value)}>
            <option value="all-show">Alle anzeigen</option>
            {animalsGroupList.map((aName: string) => <option key={aName} value={aName}>{aName}</option>)}
        </select>
        
        {/*Choose specific image*/}
        <h4 className="mt-3">Wähle das Bild</h4>
        <div className="row">
            {list && list.map(
                (animal: AnimalPicture) => <AnimalChooserDisplay key={animal.id}
                                                                 animal={animal}
                                                                 animalId={props.animalId}
                                                                 changeAnimal={props.changeAnimal} />)
            }
        </div>
    </>;
};