import {Link} from "react-router-dom";

export default function LandingPage(props: { surpressRedirct?: boolean }) {
    return <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h1>Gemeinsam Wünsche erfüllen</h1>
                    <h2>Die ganze Familie wünscht sich gemeinsam</h2>
                    <h6>
                        Wir bieten eine einzigartige Plattform für Familien und Befreundete, um sich gegenseitig
                        zu beschenken.
                        Wir helfen Ihnen, sich zu organisieren und Ihren Liebsten zu zeigen, was Sie sich wünschen.
                    </h6>
                    <h6>
                        Erstellen Sie eine Wunschliste, die Ihre Familie und Befreundete sehen können. Laden Sie
                        sie ein, ihre eigene Wunschliste zu erstellen und sich gegenseitig zu beschenken.
                    </h6>
                    <h6>
                        Melden Sie sich jetzt an, um zu sehen, wie einfach und schnell es ist, Ihre persönliche
                        Wunschliste zu erstellen.
                    </h6>
                    <div>
                        <div className="text-center text-lg-start">
                            <Link to="/app/login"
                                  className="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Jetzt starten und Wünsche erfüllen</span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                    <img src="assets/img/hero-img.png" className="img-fluid" alt=""/>
                </div>
            </div>
        </div>
    </section>;
}