import { useCallback, useState } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import { Link, NavLink } from "react-router-dom";
import { isLoggedIn } from "../api/apiConfig";
import useLogout from "../hooks/useLogout";
import useScrollEventListener from "../hooks/useScrollEvenListener";


export default function Header(props: {links: {to: string, title: string, end?: boolean}[], webpage?: boolean}): JSX.Element {

    const collapsHeader = useScrollEventListener({scrollY: 10}).triggering
    const [toggle, setToggle] = useState(false)
    const logout = useLogout()


    const navLinkClassName = useCallback((props: { isActive: boolean, isPending: boolean }) => {
        const baseClass = ["nav-link", "scrollto", "px-3"]
        props.isActive && baseClass.push("active")
        return baseClass.join(" ")
    }, [])

    return  <header id="header" className={'header fixed-top' + (collapsHeader || toggle ? ' header-scrolled' : '')}>
                <Container fluid className="container-xl">
                    <Navbar collapseOnSelect expand="lg" className="w-100 d-flex align-items-center justify-content-between">
                        <Link to="/" className="logo d-flex align-items-center"><img src="/assets/icons/android/android-icon-192x192.png" alt=""/>&nbsp;<span>SchenkSchatzi</span></Link>
                        <NavbarToggle onClick={() => setToggle(!toggle)} />
                        <NavbarCollapse style={{flexGrow: "unset"}}>
                        {
                            props.links.map(link => <NavLink to={link.to} end={link.end} className={navLinkClassName} key={link.to}>{link.title}</NavLink>)
                        }
                        {
                            isLoggedIn() ? <><Link className="nav-link px-3" to={props.webpage ? "/app" : "/"}>{props.webpage ? "Zum Dashboard" : "Zur Website"}</Link><Button onClick={logout} className="ms-4">Logout</Button></> : <><Link className="nav-link" to="/app/login">Login</Link><Link className="getstarted" to="/app/register">Create Account</Link></>
                        }
                        </NavbarCollapse>            
                        {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
                    </Navbar>
                </Container>
            </header>
}